import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Typography } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import SoonButton from './SoonButton';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  // DELETE THIS AFTER THE JOB OFFERS HAVE BEEN CONNECTED
  const isDisabled = title === 'Job offers';

  const navItemStyles = (theme) => ({
    '&.active': {
      color: 'text.primary',
      bgcolor: 'action.selected',
      fontWeight: 'fontWeightBold',
    },
    ...(isDisabled && {
      pointerEvents: 'none', // Disable link
      color: theme.palette.text.disabled, // Gray out text
      // Add additional styling for disabled state here
    }),
  });

  return (
    <StyledNavItem
      component={isDisabled ? 'div' : RouterLink} // Change component based on condition
      to={!isDisabled ? path : '#'} // Disable navigation for "Job offer"
      sx={navItemStyles}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText
        disableTypography
        primary={title}
        sx={{
          flex: '0 0 auto',
        }}
      />
      {info && info}
      {isDisabled && <SoonButton />}
    </StyledNavItem>
  );
}
