import { useState } from 'react';
import { Box, Button, CircularProgress, Divider, Grid, keyframes, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CreateNewAccount from './CreateNewAccount';
import DeleteRecruiterAccount from '../lead-components/DeleteRecruiterAccount';
import SnackbarNotification from '../lead-components/SnackBarNotification';
import useDeleteRecruiter from '../../hooks/useDeleteRecruiter';

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

const AccountSettings = ({ user, allRecruiters, setAllRecruiters, recruiterLoader }) => {
  const [currentRecruiter, setCurrentRecruiter] = useState({});
  const [visibleCount, setVisibleCount] = useState(4);
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
  const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);
  const [changeRecruiterToId, setChangeRecruiterToId] = useState('');
  const [notificationHandler, setNotificationHandler] = useState({
    title: '',
    severity: '',
  });
  const [openNotification, setOpenNotification] = useState(false);
  const [recruiterStatusLoading, setRecruiterStatusLoading] = useState(null);
  const handleHideNotification = () => {
    setOpenNotification((prev) => !prev);
  };

  const closeDeleteAccount = () => {
    setOpenDeleteAccount(false);
    setChangeRecruiterToId('');
  };

  const handleRecruiterChange = (recruiterID) => {
    setChangeRecruiterToId(recruiterID);
  };

  const handleChangeRecruiterStatus = async (recruiterID, newStatus) => {
    setRecruiterStatusLoading(recruiterID);

    try {
      const response = await fetch(`https://app.truckersflow.com/wp-json/data-api/v1/recruiter`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization:
            'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
        },
        body: JSON.stringify({
          recruiter_id: recruiterID,
          recruiter_status: newStatus,
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'An error happened');
      }
      const updatedRecruiters = allRecruiters.map((recruiter) =>
        recruiter.recruiter_id === recruiterID ? { ...recruiter, recruiter_status: newStatus } : recruiter
      );
      setAllRecruiters(updatedRecruiters);
    } catch (error) {
      setCreateAccountError(error.message);
    }
    setRecruiterStatusLoading(null);
  };

  const handleDeleteAccount = async () => {
    setDeleteAccountLoading(true);
    const bodyData = { recruiter_id: currentRecruiter.recruiter_id, recruiter_id_reassign: changeRecruiterToId };
    const notificationTitle = (
      <>
        You have successfully deleted the account of <strong>{currentRecruiter.recruiter_full_name}</strong>
      </>
    );
    try {
      await useDeleteRecruiter(bodyData);

      setAllRecruiters((prevAccounts) =>
        prevAccounts.filter((account) => account.recruiter_id !== currentRecruiter.recruiter_id)
      );
      setNotificationHandler({
        title: notificationTitle,
        severity: 'success',
      });
    } catch (error) {
      setNotificationHandler({
        title: 'Something went wrong, the account was not deleted. Please try again',
        severity: 'error',
      });
    }
    setOpenNotification(true);
    setDeleteAccountLoading(false);
    closeDeleteAccount();
  };

  const showMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };

  const renderContent = () => {
    if (allRecruiters.length > 0) {
      return (
        <>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
              mb: '20px',
            }}
          >
            Recruiter accounts:
          </Typography>
          {allRecruiters
            .filter((recruiter) => recruiter.recruiter_status && recruiter.recruiter_status !== 'deleted')
            .slice(0, visibleCount)
            .map((account, idx) => {
              const isActive = account.recruiter_status === 'active';
              const buttonLabel = isActive ? 'Pause Account' : 'Unpause Account';
              const newStatus = isActive ? 'disabled' : 'active';
              return (
                <Box
                  mb={2}
                  key={idx}
                  width="360px"
                  sx={{
                    background: 'linear-gradient(90deg, #FFFFFF 0%, #F1F1F1 130%)',
                    p: '26px 16px',
                    borderRadius: '8px',
                    border: '1px solid #E0E0E0',
                  }}
                >
                  <Typography
                    sx={{
                      mb: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Name: {account.recruiter_full_name}
                  </Typography>
                  <Typography
                    sx={{
                      mb: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Email: {account.recruiter_email}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      mb: 2,
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        mr: 1,
                      }}
                    >
                      Status
                    </Typography>
                    <CircleIcon
                      fontSize="small"
                      sx={{
                        color: account.recruiter_status === 'active' ? 'green' : ' red',
                        transition: 'color 0.5s ease-in-out',
                        animation: `${pulse} 2s infinite ease-in-out`,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Button
                      onClick={() => handleChangeRecruiterStatus(account.recruiter_id, newStatus)}
                      variant="contained"
                      disabled={recruiterStatusLoading === account.recruiter_id}
                      sx={{
                        bgcolor: isActive ? '#00D0FF' : '#4cbd29',
                        width: '150px',
                        boxShadow: '0 4px 4px 0 #00000040',
                        borderRadius: '12px',
                        color: 'white',
                        '&:hover': {
                          boxShadow: '0 6px 6px 0 #00000040',
                          bgcolor: isActive ? '#00D0D1' : '#BEE6CE',
                        },
                      }}
                    >
                      {recruiterStatusLoading === account.recruiter_id ? (
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '12px',
                          }}
                        >
                          <CircularProgress size={24} />
                        </Box>
                      ) : (
                        buttonLabel
                      )}
                    </Button>
                    <Button
                      onClick={() => {
                        setCurrentRecruiter(account);
                        setOpenDeleteAccount(true);
                      }}
                      color="error"
                      sx={{
                        ml: 'auto',
                        width: '150px',
                        borderRadius: '12px',
                      }}
                    >
                      Delete Account
                    </Button>
                  </Box>
                </Box>
              );
            })}
        </>
      );
    } else if (allRecruiters.length === 0 && recruiterLoader) {
      return (
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <CircularProgress sx={{ mt: '20px', color: '#028090' }} size={51} color="inherit" />
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography>Created recruiter accounts will display here</Typography>
        </Box>
      );
    }
  };

  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          color: '#6D6D6D',
        }}
      >
        Manage settings for your recruiter accounts
      </Typography>
      <Divider sx={{ borderStyle: 'dashed', my: 3, borderBlockWidth: '1px' }} />
      <Grid
        container
        spacing={2}
        sx={{
          my: '20px',
        }}
        flexDirection={'row-reverse'}
      >
        <Grid item xs={12} sm={8}>
          <Box width="70%" ml={'auto'}>
            {renderContent()}
            {visibleCount <
              allRecruiters.filter(
                (recruiter) => recruiter.recruiter_status && recruiter.recruiter_status !== 'deleted'
              ).length && <Button onClick={showMoreItems}>Show More</Button>}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} textAlign={'center'}>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
              mb: '20px',
            }}
          >
            Create new recruiter account:
          </Typography>
          <CreateNewAccount setAllRecruiters={setAllRecruiters} user={user} />
        </Grid>
      </Grid>
      <DeleteRecruiterAccount
        closeDeleteAccount={closeDeleteAccount}
        deleteAccountLoading={deleteAccountLoading}
        handleDeleteAccount={handleDeleteAccount}
        openDeleteAccount={openDeleteAccount}
        currentRecruiter={currentRecruiter}
        handleRecruiterChange={handleRecruiterChange}
        allRecruiters={allRecruiters}
      />
      <SnackbarNotification
        showNotification={openNotification}
        notificationHandler={notificationHandler}
        handleHideNotification={handleHideNotification}
      />
    </Grid>
  );
};

export default AccountSettings;
