// ----------------------------------------------------------------------
import offers from './offers';

const account = {
  displayName: offers[0].companyName,
  email: 'demo@minimals.cc',
  photoURL: offers[0].companyLogo,
};

export default account;
