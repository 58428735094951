import React from 'react';
import CustomToolTip from './CustomToolTip';
import PeopleIcon from '@mui/icons-material/People';

export const DuplicateLeadIcon = () => {
  return (
    <CustomToolTip toolTipTitle={'Duplicate Lead - check activity log'} placement={'top'} offset={[0, 0]}>
      <PeopleIcon
        sx={{
          fontSize: '14px',
          mr: '5px',
          color: '#028090',
          transition: 'color 0.3s ease',
          '&:hover': {
            color: '#026a73',
          },
        }}
      />
    </CustomToolTip>
  );
};
