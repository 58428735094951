import React from 'react';
import { Box, DialogTitle } from '@mui/material';
import { LeadCardHeaderTab } from './LeadCardHeaderTab';

const HEADER_TABS = [
  {
    tabPosition: 1,
    title: 'Lead Card',
  },
  {
    tabPosition: 2,
    title: 'Information Preview',
  },
  {
    tabPosition: 3,
    title: 'Activity Log',
  },
];

const LeadCardHeader = ({ leadFullName, handleTabClick, openTab }) => {
  return (
    <Box
      sx={{
        py: '6px',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        zIndex: 1,
      }}
    >
      <DialogTitle
        sx={{
          fontSize: '24px !important',
          py: '6px',
          textAlign: 'center',
          textTransform: 'capitalize',
        }}
      >
        {leadFullName}
      </DialogTitle>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          py: '5px',
        }}
      >
        {HEADER_TABS.map(({ title, tabPosition }) => (
          <React.Fragment key={tabPosition}>
            <LeadCardHeaderTab
              title={title}
              tabPosition={tabPosition}
              handleTabClick={handleTabClick}
              openTab={openTab}
            />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default LeadCardHeader;
