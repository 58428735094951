import React from 'react';
import { Box, Typography } from '@mui/material';

export const LeadCardHeaderTab = ({ title, tabPosition, handleTabClick, openTab }) => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        width: '30%',
        cursor: 'pointer',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: tabPosition === openTab ? '2px solid #028090' : '2px solid #DEDEDE',
        '&:hover': {
          color: 'black',
          backgroundColor: 'rgba(173, 216, 230, 0.4)',
        },
        borderRadius: '6px',
      }}
      onClick={() => handleTabClick(tabPosition)}
    >
      <Typography
        variant="body2"
        component="p"
        sx={{
          fontWeight: tabPosition === openTab ? ' bold !important' : '',
          color: tabPosition === openTab ? ' #028090' : '',
          borderRadius: '6px',
          fontSize: '16px',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
