import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { UserContext } from 'src/context/userContext';

const PasswordReset = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { key } = useParams();
  const { setResetToken } = useContext(UserContext);

  useEffect(() => {
    if (typeof key === 'string') {
      setResetToken(key);

      const pathSegments = location.pathname.split('/');
      if (location.pathname.endsWith('/')) {
        pathSegments.pop();
        pathSegments.pop();
      } else {
        pathSegments.pop();
      }
      const urlWithoutToken = pathSegments.join('/');

      // Using 'replace' to navigate without adding a new entry in the history stack
      navigate(urlWithoutToken);
    } else {
      console.error('Token is not a string:', key);
    }
  }, [key, location, navigate]);

  return null;
};

export default PasswordReset;
