import { Grid, Typography } from '@mui/material';

function DisplayField({ title, value, capitalize = false }) {
  return (
    <>
      <Grid item xs={5}>
        <Typography
          sx={{
            textAlign: 'left',
            mr: '8px',
            pl: '8px',
          }}
          my="3px"
        >
          {title}:
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography
          color="text.secondary"
          my="3px"
          sx={{
            textAlign: 'left',
            textTransform: capitalize ? 'capitalize' : 'none',
            wordWrap: 'break-word',
            ml: '8px',
            fontWeight: '600',
            color: 'black',
            maxWidth: '100%',
            overflow: 'hidden',
            whiteSpace: 'normal',
            pr: '8px',
          }}
        >
          {value}
        </Typography>
      </Grid>
    </>
  );
}

export default DisplayField;
