import PropTypes from 'prop-types';
// @mui
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Link, Typography } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ products, offers, ...other }) {
  return (
    <>
      <Grid
        container
        spacing={5}
        mt={0}
        id="search-scroll-anchor"
        sx={{
          '@media (max-width: 600px)': {
            '& > *': {
              margin: 0,
              width: '100%',
            },
          },
        }}
      >
        {offers.map((jobOffer, id) =>
          jobOffer.companiesJobPostsData.map((offer, idx) => (
            <Grid key={idx} item xs={12}>
              <Card
                sx={{
                  minHeight: 400,
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: ' 0px 10px 17px 0px rgb(0 0 0 / 13%) !important',
                  height: '100%',
                  transition: 'background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s',
                  padding: '40px 40px 20px 40px',
                  border: '8px',
                  borderRadius: '8px',
                  ':hover': {
                    boxShadow: '0px 10px 17px 0px rgb(0 0 0 / 30%) !important',
                  },
                }}
              >
                <CardMedia
                  sx={{
                    height: 126,
                    width: 126,
                    objectFit: 'contain',
                    mx: 'auto',
                    cursor: 'pointer',
                  }}
                  component="div"
                >
                  <Link
                    href={`https://openotr.com/company/${jobOffer.companySlug}/${offer.companyJobSlug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box component="div" sx={{ width: 126, height: 126 }}>
                      <img
                        src={jobOffer.companyLogo}
                        alt={jobOffer.companyName}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </Box>
                  </Link>
                </CardMedia>
                <CardContent>
                  <Link
                    href={`https://openotr.com/company/${jobOffer.companySlug}/${offer.companyJobSlug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      textTransform="capitalize"
                      sx={{
                        color: 'black',
                        '@media (min-width:0px) and (max-width:380.00px)': {
                          fontSize: '20px', // mobile s / m breakpoint
                        },
                        '@media (min-width:381.00px) and (max-width:1559.95px)': {
                          fontSize: '24px', // md breakpoint
                        },
                        cursor: 'pointer',
                      }}
                      mb={3}
                      component="div"
                    >
                      Your offer for:{' '}
                      <strong>
                        <i>{offer.companyJobSlug.replace('-', ' ')}</i>
                      </strong>
                    </Typography>
                  </Link>
                  {/* <Typography variant="body2" mb={2} color="text.primary" fontWeight="400" fontSize="16px" lineHeight="150%">
                    Application for: <strong><i>{offer.companyJobSlug.replace('-', ' ')}</i></strong>
                  </Typography> */}
                  <Typography variant="body1" color="text.primary" fontWeight="400" fontSize="16px" lineHeight="150%">
                    {offer.companyJobTagline}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    mt: 'auto',
                    display: 'flex',
                    alignSelf: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <Button
                    variant="contained"
                    component="a"
                    size="small"
                    href={`https://openotr.com/company/${jobOffer.companySlug}/${offer.companyJobSlug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      px: '30px',
                      py: '10px',
                      alignSelf: 'end',
                      borderRadius: '8px',
                      backgroundColor: '#028090',
                      fontWeight: '300',
                      textTransform: 'uppercase',
                      ':hover': {
                        bgcolor: '#AEECEF',
                        color: 'black',
                        fontWeight: 'bold',
                      },
                    }}
                  >
                    See More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
      {/* <Grid container spacing={3} {...other}>
        {products.map((product) => (
          <Grid key={product.id} item xs={12} sm={6} md={6}>
            <ShopProductCard product={product} />
          </Grid>
        ))}
      </Grid> */}
    </>
  );
}
