import React, { useContext, useState } from 'react';
import { Box, Dialog, DialogActions, DialogTitle, DialogContent, Slide, CircularProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { UserContext } from 'src/context/userContext';
import SnackbarNotification from './SnackBarNotification';
import useCreateNewLead from 'src/hooks/useCreateNewLead';
import { CancelButton, SaveButton } from './DriverCardModal';
import CreateLeadFields from './CreateLeadFields';
import CloseModalIcon from '../globalCloseIcon/closeModalIcon';
import dayjs from 'dayjs';
import { currentDateToTimezone } from 'src/helpers/ConvertToTimezone';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNewLeadModal = ({
  openCreateLead,
  setOpenCreateLead,
  setAllDrivers,
  allDrivers,
  setNewestLead,
  handleOpenLeadCard,
  allRecruiters,
}) => {
  const { user } = useContext(UserContext);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationHandler, setNotificationHandler] = useState({
    title: '',
    severity: '',
  });
  const handleCloseCreateLead = (resetForm) => {
    setOpenCreateLead(false);
    resetForm();
  };

  const handleHideNotification = () => {
    setOpenNotification((prev) => !prev);
  };
  const validationSchema = Yup.object().shape({
    lead_email: Yup.string()
      .email('* Invalid email format')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, '* Invalid email format')
      .required('* Required'),
    first_name: Yup.string().required('* Required'),
    last_name: Yup.string().required('* Required'),
    phone_number: Yup.string().required('* Required'),
    lead_driver_position: Yup.string().required('* Required'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const bodyData = {
      ...values,
      company_id: user.company_id,
      company: user.company_name,
      recruiter_id: user.recruiter_id ?? values.recruiter_id,
    };
    setSubmitting(true);

    try {
      const response = await useCreateNewLead(bodyData);
      setNewestLead(response.lead_id);
      const timestamp = [dayjs(currentDateToTimezone()).format('YYYY-MM-DD HH:mm:ss')];

      const timeLogEntry = {
        [timestamp]: {
          recruiter_id: response.lead_recruiter_id,
          event_name: 'first_touch',
          content: response.lead_recruiter_id,
        },
      };

      const createdLead = { ...response, lead_event_log: timeLogEntry };

      const updatedLeads = [createdLead, ...allDrivers];
      if (updatedLeads.length > 50) {
        updatedLeads.pop();
      }

      setAllDrivers(updatedLeads);
      handleOpenLeadCard(response.lead_id, updatedLeads);
      setNotificationHandler({
        title: 'You have successfully created a Lead!',
        severity: 'success',
      });
      setOpenNotification(true);
    } catch (error) {
      console.error(error);
      setNotificationHandler({
        title: 'Something went wrong, the Lead was not created! Please try again',
        severity: 'error',
      });
      setOpenNotification(true);
    }

    setSubmitting(false);

    handleCloseCreateLead(resetForm);
  };

  return (
    <>
      <Formik
        initialValues={{
          company_id: 0,
          company: '',
          job_id: 999999999,
          lead_source: 2,
          lead_email: '',
          first_name: '',
          last_name: '',
          experience: '',
          state: '',
          phone_number: '',
          interested_in: '',
          owner_op: '',
          are_you_an_owner_op: '',
          kind_of_trailer: '',
          cdl: '',
          trailer_experience: '',
          endorsements: '',
          pay_structure: '',
          status: 'not contacted',
          recruiter_id: '',
          lead_driver_position: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors, touched, isSubmitting, resetForm, setErrors }) => (
          <Dialog
            open={openCreateLead}
            TransitionComponent={Transition}
            PaperProps={{
              sx: {
                width: '35%',
                maxWidth: 'none',
                height: 'auto',
                overflow: 'visible',
                position: 'relative',
              },
            }}
            onClose={() => handleCloseCreateLead(resetForm)}
            aria-describedby="alert-dialog-slide-description"
          >
            <CloseModalIcon handleClose={handleCloseCreateLead} resetForm={resetForm} />
            <Box
              sx={{
                py: '6px',
                boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',
                borderRadius: '10px',
                zIndex: 1,
              }}
            >
              <DialogTitle
                sx={{
                  fontSize: '24px !important',
                  py: '6px',
                  textAlign: 'center',
                  textTransform: 'capitalize',
                }}
              >
                Create New Lead
              </DialogTitle>
            </Box>
            <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <DialogContent
                className="modern-scrollbar"
                sx={{
                  flex: 1,
                  overflowY: 'auto',
                  p: '20px 40 px',
                }}
              >
                <CreateLeadFields
                  errors={errors}
                  touched={touched}
                  setErrors={setErrors}
                  allRecruiters={allRecruiters}
                  isUserAdmin={!user.recruiter_id}
                />
              </DialogContent>

              <DialogActions>
                <CancelButton onClick={() => handleCloseCreateLead(resetForm)}>Cancel</CancelButton>
                <SaveButton type="submit" disabled={isSubmitting} isSubmitting={isSubmitting}>
                  {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                </SaveButton>
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
      <SnackbarNotification
        showNotification={openNotification}
        notificationHandler={notificationHandler}
        handleHideNotification={handleHideNotification}
      />
    </>
  );
};

export default AddNewLeadModal;
