import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalLoading from 'src/components/globalLoading';
import useAuthenticateUser from 'src/hooks/useAuthenticateUser';

export const UserContext = createContext({});

export const Provider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchingDataLoader, setFetchingDataLoader] = useState(false);
  const [resetToken, setResetToken] = useState('');

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));

    if (storedUser) {
      //   const parsedUser = JSON.parse(storedUser);
      //   setUser(parsedUser);
      //   setIsLoggedIn(true);
      // }
      // setLoading(false);

      const authenticate = async (user) => {
        try {
          const authenticatedUser = await useAuthenticateUser(user.company_access_token ?? user.recruiter_access_token);

          if (
            Number(authenticatedUser) === Number(user.company_id) ||
            Number(authenticatedUser) === Number(user.recruiter_id)
          ) {
            setUser(user);
            setIsLoggedIn(true);
          } else {
            throw new Error('Authentication failed: Company ID mismatch');
          }
        } catch (error) {
          console.error('Authentication error:', error);
          setIsLoggedIn(false);
          localStorage.removeItem('user');
          setUser({});
        } finally {
          setLoading(false);
        }
      };

      authenticate(storedUser);
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) return <GlobalLoading />;

  const handleLogOut = () => {
    setIsLoggedIn(false);
    navigate('/login');
    localStorage.removeItem('user');
    setUser(undefined);
  };

  const userContextDataObj = {
    user,
    setUser,
    isLoggedIn,
    setIsLoggedIn,
    handleLogOut,
    loading,
    fetchingDataLoader,
    setFetchingDataLoader,
    setResetToken,
    resetToken,
  };
  return <UserContext.Provider value={userContextDataObj}>{children}</UserContext.Provider>;
};
