import React from 'react';
import { Snackbar, Alert, Slide } from '@mui/material';
import ReactDOM from 'react-dom';

const SlideTransition = (props) => {
  return <Slide {...props} direction="down" />;
};

export default function SnackbarNotification({ notificationHandler, showNotification, handleHideNotification }) {
  return ReactDOM.createPortal(
    <Snackbar
      open={showNotification}
      autoHideDuration={4000}
      onClose={handleHideNotification}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleHideNotification}
        severity={notificationHandler.severity || 'success'}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {notificationHandler.title}
      </Alert>
    </Snackbar>,
    document.body
  );
}
