export const generatePassword = () => {
  const chars = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*()_-+=<>?';

  const randomChar = () => chars[Math.floor(Math.random() * chars.length)];
  const randomUppercaseChar = () => uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
  const randomNumber = () => numbers[Math.floor(Math.random() * numbers.length)];
  const randomSpecialChar = () => specialChars[Math.floor(Math.random() * specialChars.length)];

  let newPassword = randomUppercaseChar() + randomSpecialChar() + randomSpecialChar() + randomNumber();

  const randomFunctions = [randomChar, randomUppercaseChar, randomNumber, randomSpecialChar];

  for (let i = 4; i < 16; i++) {
    let randomFunction = randomFunctions[Math.floor(Math.random() * randomFunctions.length)];
    newPassword += randomFunction();
  }
  newPassword = newPassword
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');

  return newPassword;
};
