import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@mui/material';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteLeadPopover = ({
  closeDeletePopover,
  deleteLeadLoading,
  handleDeleteLead,
  openDeleteLeadCard,
  leadFullName,
}) => {
  return (
    <Dialog
      open={openDeleteLeadCard}
      TransitionComponent={Transition}
      PaperProps={{ sx: { width: '50%', maxWidth: 'none' } }}
      onClose={closeDeletePopover}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          fontSize: '24px !important',
          py: '6px',
          textAlign: 'center',
          textTransform: 'capitalize',
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: '24px !important',
          }}
        >
          Delete
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: '24px !important',
            fontWeight: 'bold',
          }}
        >
          {` ${leadFullName}?`}
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          p: '30px !important',
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{
            marginBottom: '20px',
            fontSize: '20px',
            fontWeight: '600',
            color: 'red',
          }}
        >
          This will permanently delete this lead
        </Typography>
        <Typography>This action is not reversible. None of this driver information will be recoverable.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDeletePopover} color="error" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleDeleteLead}
          disabled={deleteLeadLoading}
          color="success"
          variant="contained"
          sx={{
            minWidth: '86px',
          }}
        >
          {deleteLeadLoading ? <CircularProgress size={24} /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteLeadPopover;
