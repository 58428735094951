import React from 'react';
import { Field } from 'formik';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import InputErrorField from 'src/sections/auth/login/InputErrorField';
import RecruiterSelect from './RecruiterSelect';
import PositionSelect from './PositionSelect';
// import ColoredSelect from './ColoredSelect';

// const experienceOptions = ['0-5 months', '5-12 months', '1-2 years', '2-3 years', '3+ years'];
// const positionOptions = ['Company Driver', 'LP', 'Owner-operator', 'Truck rental'];

const CreateLeadFields = ({ errors, touched, setErrors, allRecruiters, isUserAdmin }) => {
  const clearAllErrors = () => {
    setErrors({});
  };
  // const screenResponsive = useMediaQuery('(max-width: 1367px)');

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={1}
      sx={{
        mt: '4px',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          '& > *': {
            justifyContent: 'center',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            position: 'relative',
          }}
        >
          <label htmlFor="lead_driver_position" style={{ width: '125px', textAlign: 'left', whiteSpace: 'nowrap' }}>
            Position:
          </label>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              minWidth: '100px',
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: errors.lead_driver_position ? 'red' : '',
                  borderWidth: errors.lead_driver_position ? '1.5px' : '',
                },
                color: errors.lead_driver_position ? 'red' : '',
              },
            }}
          >
            <Field
              name="lead_driver_position"
              sx={{
                flexGrow: 1,
                maxWidth: '300px',
              }}
            >
              {({ field, form }) => (
                <PositionSelect
                  selectedPosition={field.value}
                  width="300px"
                  onChange={(changedPosition) => {
                    form.setFieldValue(field.name, changedPosition);
                  }}
                />
              )}
            </Field>

            {errors.lead_driver_position && <InputErrorField inputError={errors.lead_driver_position} left="0" />}
          </Box>
        </Box>
        {isUserAdmin && allRecruiters.length > 1 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: '10px 10px 10px 0',
              position: 'relative',
            }}
          >
            <label htmlFor="first_name" style={{ width: '125px', textAlign: 'left', whiteSpace: 'nowrap' }}>
              Recruiter:
            </label>
            <Field
              name="recruiter_id"
              sx={{
                flexGrow: 1,
                maxWidth: '300px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: errors.recruiter_id ? 'red' : '',
                  borderWidth: errors.recruiter_id ? '1.5px' : '',
                },
                '& .MuiFormLabel-root': {
                  color: errors.recruiter_id ? 'red' : '',
                },
              }}
            >
              {({ field, form }) => (
                <RecruiterSelect
                  allRecruiters={allRecruiters}
                  selectedRecruiter={field.value}
                  width="300px"
                  onChange={(changedRecruiter) => {
                    form.setFieldValue(field.name, changedRecruiter);
                  }}
                />
              )}
            </Field>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            position: 'relative',
          }}
        >
          <label htmlFor="first_name" style={{ width: '125px', textAlign: 'left', whiteSpace: 'nowrap' }}>
            First Name:
          </label>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              minWidth: '100px',
              maxWidth: '300px',
            }}
          >
            <Field
              as={TextField}
              id="first_name"
              name="first_name"
              placeholder="John"
              variant="outlined"
              onFocus={() => clearAllErrors()}
              sx={{
                flexGrow: 1,
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: errors.first_name ? 'red' : '',
                  borderWidth: errors.first_name ? '1.5px' : '',
                },
                '& .MuiFormLabel-root': {
                  color: errors.first_name ? 'red' : '',
                },
              }}
              inputProps={{
                sx: {
                  padding: '2px 10px',
                },
              }}
            />
            {touched.first_name && errors.first_name && <InputErrorField inputError={errors.first_name} left="0" />}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            position: 'relative',
          }}
        >
          <label htmlFor="last_name" style={{ width: '125px', textAlign: 'left', whiteSpace: 'nowrap' }}>
            Last Name:
          </label>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              minWidth: '100px',
              maxWidth: '300px',
            }}
          >
            <Field
              as={TextField}
              id="last_name"
              name="last_name"
              placeholder="Doe"
              variant="outlined"
              onFocus={() => clearAllErrors()}
              sx={{
                flexGrow: 1,
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: errors.last_name ? 'red' : '',
                  borderWidth: errors.last_name ? '1.5px' : '',
                },
                '& .MuiFormLabel-root': {
                  color: errors.last_name ? 'red' : '',
                },
              }}
              inputProps={{
                sx: {
                  padding: '2px 10px',
                },
              }}
            />
            {touched.last_name && errors.last_name && <InputErrorField inputError={errors.last_name} left="0" />}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            position: 'relative',
          }}
        >
          <label htmlFor="lead_email" style={{ width: '125px', textAlign: 'left', whiteSpace: 'nowrap' }}>
            Email:
          </label>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              minWidth: '100px',
              maxWidth: '300px',
            }}
          >
            <Field
              as={TextField}
              sx={{
                flexGrow: 1,
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: errors.lead_email ? 'red' : '',
                  borderWidth: errors.lead_email ? '1.5px' : '',
                },
                '& .MuiFormLabel-root': {
                  color: errors.lead_email ? 'red' : '',
                },
              }}
              id="lead_email"
              name="lead_email"
              placeholder="john.doe@gmail.com"
              variant="outlined"
              onFocus={() => clearAllErrors()}
              inputProps={{
                sx: {
                  padding: '2px 10px',
                },
              }}
            />
            {touched.lead_email && errors.lead_email && <InputErrorField inputError={errors.lead_email} left="0" />}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            position: 'relative',
          }}
        >
          <label htmlFor="phone_number" style={{ width: '125px', textAlign: 'left', whiteSpace: 'nowrap' }}>
            Phone Number:
          </label>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              minWidth: '100px',
              maxWidth: '300px',
            }}
          >
            <Field
              as={TextField}
              id="phone_number"
              name="phone_number"
              placeholder="8005550123"
              variant="outlined"
              onFocus={() => clearAllErrors()}
              sx={{
                flexGrow: 1,
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: errors.phone_number ? 'red' : '',
                  borderWidth: errors.phone_number ? '1.5px' : '',
                },
                '& .MuiFormLabel-root': {
                  color: errors.phone_number ? 'red' : '',
                },
              }}
              inputProps={{
                sx: {
                  padding: '2px 10px',
                },
              }}
            />
            {touched.phone_number && errors.phone_number && (
              <InputErrorField inputError={errors.phone_number} left="0" />
            )}
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            position: 'relative',
            justifyContent: 'space-between',
          }}
        >
          <label htmlFor="state" style={{ marginRight: 8, textAlign: 'center', whiteSpace: 'nowrap' }}>
            State:
          </label>
          <Field
            as={TextField}
            id="state"
            name="state"
            placeholder="Texas"
            variant="outlined"
            onFocus={() => clearAllErrors()}
            sx={{ flexGrow: 1, maxWidth: '300px' }}
            inputProps={{
              sx: {
                padding: '2px 10px',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            maxWidth: screenResponsive ? '90%' : '70%',
            position: 'relative',
            justifyContent: 'space-between',
          }}
        >
          <label htmlFor="experience" style={{ marginRight: 8, textAlign: 'center', whiteSpace: 'nowrap' }}>
            Experience:
          </label>
          <Field name="experience">
            {({ field }) => (
              <FormControl
                fullWidth
                sx={{
                  maxWidth: '300px',
                }}
              >
                <Select
                  {...field}
                  labelId="experience"
                  id="experience"
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === '') {
                      return <span style={{ color: '#999' }}>select experience</span>;
                    }
                    return experienceOptions.find((option) => option === selected);
                  }}
                >
                  {experienceOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Field>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            maxWidth: screenResponsive ? '90%' : '70%',
            position: 'relative',
            justifyContent: 'space-between',
          }}
        >
          <label htmlFor="interested_in" style={{ marginRight: 8, textAlign: 'center', whiteSpace: 'nowrap' }}>
            Position:
          </label>
          <Field name="interested_in">
            {({ field }) => (
              <FormControl
                fullWidth
                sx={{
                  maxWidth: '300px',
                }}
              >
                <Select
                  {...field}
                  id="interested_in"
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === '') {
                      return <span style={{ color: '#999' }}> select position</span>;
                    }
                    return positionOptions.find((option) => option === selected);
                  }}
                >
                  {positionOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Field>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            maxWidth: screenResponsive ? '90%' : '70%',
            justifyContent: 'space-between',
          }}
        >
          <label htmlFor="status" style={{ marginRight: 8, display: 'flex', alignItems: 'center' }}>
            Status:
          </label>
          <Field name="status">
            {({ field, form }) => (
              <ColoredSelect
                initialValue={field.value || 'not contacted'}
                onChange={(newStatus) => {
                  form.setFieldValue(field.name, newStatus);
                }}
              />
            )}
          </Field>
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          '& > *': {
            mx: 'auto',
          },
        }}
      >
        <Typography lineHeight="42px" fontSize="20px" fontWeight="600" textAlign="center">
          Driver specification:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pr: '10px',
            position: 'relative',
            maxWidth: screenResponsive ? '90%' : '70%',
          }}
        >
          <Field name="cdl">
            {({ field }) => (
              <FormControl
                component="fieldset"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <label htmlFor="cdl" style={{ marginRight: 8, textAlign: 'center', whiteSpace: 'nowrap' }}>
                    Has CDL:
                  </label>
                  <RadioGroup
                    {...field}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="cdl"
                    value={field.value}
                    onChange={field.onChange}
                    row
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <FormControlLabel value="Yes" control={<Radio size="small" />} label="Yes" />
                    <FormControlLabel
                      value="No"
                      sx={{
                        mr: 0,
                      }}
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </Box>
              </FormControl>
            )}
          </Field>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            position: 'relative',
            maxWidth: screenResponsive ? '90%' : '70%',
          }}
        >
          <Field name="are_you_an_owner_op">
            {({ field }) => (
              <FormControl
                component="fieldset"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <label
                    htmlFor="are_you_an_owner_op"
                    style={{ marginRight: 8, textAlign: 'center', whiteSpace: 'nowrap' }}
                  >
                    Owner Operator:
                  </label>
                  <RadioGroup
                    {...field}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="are_you_an_owner_op"
                    value={field.value}
                    onChange={field.onChange}
                    row
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <FormControlLabel value="Yes" control={<Radio size="small" />} label="Yes" />
                    <FormControlLabel
                      value="No"
                      sx={{
                        mr: 0,
                      }}
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </Box>
              </FormControl>
            )}
          </Field>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            maxWidth: screenResponsive ? '90%' : '70%',
            position: 'relative',
            justifyContent: 'space-between',
          }}
        >
          <label htmlFor="owner_op" style={{ marginRight: 8, textAlign: 'center', whiteSpace: 'nowrap' }}>
            Truck Model:
          </label>
          <Field
            as={TextField}
            id="owner_op"
            name="owner_op"
            placeholder="99 kenworth w900"
            variant="outlined"
            sx={{ flexGrow: 1, maxWidth: '300px' }}
            inputProps={{
              sx: {
                padding: '2px 10px',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            maxWidth: screenResponsive ? '90%' : '70%',
            justifyContent: 'space-between',
          }}
        >
          <label htmlFor="kind_of_trailer" style={{ marginRight: 8, textAlign: 'center', whiteSpace: 'nowrap' }}>
            Truck type:
          </label>
          <Field
            as={TextField}
            id="kind_of_trailer"
            name="kind_of_trailer"
            placeholder="Flatbed"
            variant="outlined"
            sx={{ flexGrow: 1, maxWidth: '300px' }}
            inputProps={{
              sx: {
                padding: '2px 10px',
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            maxWidth: screenResponsive ? '90%' : '70%',
            position: 'relative',
            justifyContent: 'space-between',
          }}
        >
          <label htmlFor="trailer_experience" style={{ marginRight: 8, textAlign: 'center', whiteSpace: 'nowrap' }}>
            Trailer Experience:
          </label>
          <Field
            as={TextField}
            id="trailer_experience"
            name="trailer_experience"
            placeholder="Flatbed 2 years"
            variant="outlined"
            sx={{ flexGrow: 1, maxWidth: '300px' }}
            inputProps={{
              sx: {
                padding: '2px 10px',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            maxWidth: screenResponsive ? '90%' : '70%',
            position: 'relative',
            justifyContent: 'space-between',
          }}
        >
          <label htmlFor="endorsements" style={{ marginRight: 8, textAlign: 'center', whiteSpace: 'nowrap' }}>
            Endorsements:
          </label>
          <Field
            as={TextField}
            id="endorsements"
            name="endorsements"
            placeholder="TWIC"
            variant="outlined"
            sx={{ flexGrow: 1, maxWidth: '300px' }}
            inputProps={{
              sx: {
                padding: '2px 10px',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '10px 10px 10px 0',
            maxWidth: screenResponsive ? '90%' : '70%',
            position: 'relative',
            justifyContent: 'space-between',
          }}
        >
          <label htmlFor="pay_structure" style={{ marginRight: 8, textAlign: 'center', whiteSpace: 'nowrap' }}>
            Pay Structure:
          </label>
          <Field
            as={TextField}
            id="pay_structure"
            name="pay_structure"
            placeholder="70%"
            variant="outlined"
            sx={{ flexGrow: 1, maxWidth: '300px' }}
            inputProps={{
              sx: {
                padding: '2px 10px',
              },
            }}
          />
        </Box>*/}
      </Grid>
    </Grid>
  );
};

export default CreateLeadFields;
