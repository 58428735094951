import { Box, Button, Popover, Typography } from '@mui/material';
import React from 'react';

const DeleteNotePopover = ({ anchorDeleteNoteEl, closeDeletePopover, handleDeleteNote }) => {
  return (
    <Popover
      open={Boolean(anchorDeleteNoteEl)}
      anchorEl={anchorDeleteNoteEl}
      onClose={closeDeletePopover}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Typography sx={{ padding: '16px 16px 0 16px' }}>Are you sure you want to delete?</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
        <Button onClick={closeDeletePopover} color="error" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleDeleteNote();
            closeDeletePopover();
          }}
          color="success"
          variant="contained"
        >
          Confirm
        </Button>
      </Box>
    </Popover>
  );
};

export default DeleteNotePopover;
