import { Alert, Modal, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/system';

const ResetModals = ({
  callFailedTrigger,
  setCallFailedTrigger,
  callSucceededTrigger,
  setCallSucceededTrigger,
  displayMessage,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      <Modal
        open={callFailedTrigger}
        onClose={() => {
          setCallFailedTrigger(false);
          navigate('/');
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : '70%',
            textAlign: 'center',
            borderRadius: '4px',
          }}
        >
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert
              variant="filled"
              severity="error"
              sx={{
                borderRadius: '4px',
              }}
              onClose={() => {
                setCallFailedTrigger(false);
                navigate('/login');
              }}
            >
              {displayMessage}
            </Alert>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={callSucceededTrigger}
        onClose={() => {
          setCallSucceededTrigger(false);
          navigate('/login');
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            margin: '0',
            width: isMobile ? '90%' : '70%',
            bgcolor: '#BEE6CE',
            borderRadius: '4px',
          }}
        >
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              sx={{
                color: 'black',
                bgcolor: '#BEE6CE',
                borderRadius: '4px',
              }}
              onClose={() => {
                setCallSucceededTrigger(false);
                navigate('/login');
              }}
            >
              {displayMessage}
            </Alert>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ResetModals;
