import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, useMediaQuery } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const image = (
    <img
      src={'../assets/images/TF_Logo.png'}
      alt="Company Header Logo"
      style={{ minWidth: '243px', height: '128px', maxwidth: '100%', cursor: 'pointer' }}
    />
  );

  const logo = (
    <Box
      component="span"
      sx={{
        display: 'flex',
        alignItems: 'center',
        // marginLeft: { xs: 0, md: '40px' },
        ...sx,
      }}
      {...other} // Spread any additional props to the Box
    >
      {disabledLink ? (
        image
      ) : (
        <Link to="/" component={RouterLink}>
          {image}
        </Link>
      )}
    </Box>
  );

  return logo;
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
