import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const GlobalLoading = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 288px)',
        }}
      >
        <Typography
          sx={{
            fontSize: '40px',
            display: 'block',
            width: '100%',
            textAlign: 'center',

            color: '#028090',
          }}
        >
          Loading...
        </Typography>
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <CircularProgress sx={{ mt: '20px', color: '#028090' }} size={50} color="inherit" />
        </Box>
      </Box>
    </>
  );
};

export default GlobalLoading;
