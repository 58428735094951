import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const AutoNotification = ({ messages }) => {
  const [snackPacks, setSnackPacks] = useState([]);

  useEffect(() => {
    setSnackPacks((prevSnacks) =>
      messages.map((message, index) => ({
        id: prevSnacks.length + index,
        message,
        open: true,
      }))
    );
  }, [messages]);

  const handleClose = (event, reason, id) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackPacks((prev) => prev.filter((snack) => snack.id !== id));
  };

  const verticalOffset = 110;

  return (
    <>
      {snackPacks.map((snack, index) => (
        <Snackbar
          key={snack.id}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snack.open}
          autoHideDuration={6000}
          onClose={(event, reason) => handleClose(event, reason, index)}
          TransitionComponent={TransitionDown}
          style={{
            top: `${index * verticalOffset + 20}px`,
            right: '20px',
          }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={(event, reason) => handleClose(event, reason, snack.id)}
            style={{
              width: '300px',
              minHeight: '100px',
            }}
            severity="success"
          >
            A new Lead has just Arrived!{' '}
            <strong style={{ color: 'red', textTransform: 'capitalize' }}>{snack.message}</strong> has been added in the
            list.
          </MuiAlert>
        </Snackbar>
      ))}
    </>
  );
};

export default AutoNotification;
