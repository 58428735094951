import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../context/userContext';
import GeneralSettings from '../components/settingsComponents/GeneralSettings';
import AccountSettings from '../components/settingsComponents/AccountSettings';
import SoonButton from 'src/components/nav-section/SoonButton';
import useGetAllRecruiters from 'src/hooks/useGetAllRecruiters';

const SettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState('Accounts');
  const { user } = useContext(UserContext);
  const [allRecruiters, setAllRecruiters] = useState([]);
  const [recruiterLoader, setRecruiterLoader] = useState(true);

  useEffect(() => {
    const fetchRecruiters = async () => {
      try {
        const recruiters = await useGetAllRecruiters(user.company_id);
        if (Array.isArray(recruiters)) {
          setAllRecruiters(recruiters);
        } else {
          setAllRecruiters([]);
        }
      } catch (error) {
        console.error('Failed to fetch recruiters', error);
      }
      setRecruiterLoader(false);
    };

    fetchRecruiters();
  }, []);

  return (
    <>
      <Helmet>
        <title> Account Settings </title>
      </Helmet>

      <Container maxWidth={'100%'}>
        <Typography variant="h4" sx={{ my: 1 }}>
          Settings
        </Typography>

        <Grid
          container
          spacing={3}
          mt={0}
          sx={{
            '@media (max-width: 600px)': {
              '& > *': {
                margin: 0,
                width: '100%',
              },
            },
          }}
        >
          <Grid item xs={2} display={'flex'} flexDirection={'column'}>
            {/* <Box display={'flex'}>
              <Button
                sx={{
                  color: 'rgb(2, 128, 144)',
                  cursor: 'pointer',
                  mb: 2,
                  backgroundColor: selectedTab === 'General' ? 'rgba(32, 101, 209, 0.08)' : 'transparent',
                }}
                // onClick={() => {
                //   setSelectedTab('General');
                // }}
              >
                General
              </Button>
              <SoonButton />
            </Box> */}

            <Button
              sx={{
                color: 'rgb(2, 128, 144)',
                cursor: 'pointer',
                mb: 2,
                backgroundColor: selectedTab === 'Accounts' ? 'rgba(32, 101, 209, 0.08)' : 'transparent',
              }}
              onClick={() => {
                setSelectedTab('Accounts');
              }}
            >
              Accounts
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  mb: 3,
                }}
              >
                <Typography>
                  <Typography
                    component={'span'}
                    sx={{
                      color: '#6D6D6D',
                    }}
                  >
                    {'Settings / '}
                  </Typography>
                  <Typography component={'span'} sx={{ fontWeight: 'bold' }}>
                    {selectedTab}
                  </Typography>
                </Typography>
              </Grid>
              {selectedTab === 'General' && <GeneralSettings user={user} />}
              {selectedTab === 'Accounts' && (
                <AccountSettings
                  user={user}
                  allRecruiters={allRecruiters}
                  setAllRecruiters={setAllRecruiters}
                  recruiterLoader={recruiterLoader}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SettingsPage;
