import { useState } from 'react';
import { Box, Button, Stack, TextField, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { generatePassword } from '../../helpers/GeneratePassword';
import InputErrorField from '../../sections/auth/login/InputErrorField';
import Iconify from '../iconify';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import SnackbarNotification from '../lead-components/SnackBarNotification';

const CreateNewAccount = ({ setAllRecruiters, user }) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, '* First Name must contain at least 2 characters')
      .required('* This field is required'),
    lastName: Yup.string()
      .min(2, '* Last Name must contain at least 2 characters')
      .required('* This field is required'),
    newEmail: Yup.string()
      .email('* Please enter a valid email address')
      .matches(/^[^\s@#$%&!*]+@[^\s@#$%&!*]+\.[^\s@#$%&!*]+$/, '* Please enter a valid email address!')
      .required('* This field is required'),
    newPassword: Yup.string()
      .min(6, '* Password must contain at least 6 characters')
      .matches(/[A-Z]/, '* Password must contain at least one uppercase letter')
      .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, '* Password must contain at least one special character')
      .required('* This field is required'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], '* Password must match')
      .required('* This field is required'),
  });

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [createAccountLoading, setCreateAccountLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationHandler, setNotificationHandler] = useState({
    title: '',
    severity: '',
  });

  const handleHideNotification = () => {
    setOpenNotification((prev) => !prev);
  };
  const handleCreateAccount = async (values, { setSubmitting, resetForm }) => {
    setCreateAccountLoading(true);
    setSubmitting(true);
    try {
      const response = await fetch(`https://app.truckersflow.com/wp-json/data-api/v1/recruiter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization:
            'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
        },
        body: JSON.stringify({
          recruiter_first_name: values.firstName,
          recruiter_last_name: values.lastName,
          recruiter_username: values.firstName + '.' + values.lastName,
          recruiter_password: values.newPassword,
          recruiter_email: values.newEmail,
          recruiter_company_id: user.company_id,
          recruiter_status: 'active',
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'An error happened');
      }
      setNotificationHandler({
        title: (
          <>
            Account for{' '}
            <strong>
              {values.firstName} {values.lastName}
            </strong>{' '}
            created successfully!
          </>
        ),
        severity: 'success',
      });
      setOpenNotification(true);
      setAllRecruiters((prev) => [
        {
          recruiter_id: data.recruiter_id,
          recruiter_full_name: values.firstName + ' ' + values.lastName,
          recruiter_email: values.newEmail,
          recruiter_username: values.firstName + '.' + values.lastName,
          recruiter_status: 'active',
        },
        ...prev,
      ]);
      resetForm();
    } catch (error) {
      console.error(error);
      setNotificationHandler({
        title: error.message,
        severity: 'error',
      });
      setOpenNotification(true);
    } finally {
      setCreateAccountLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          newEmail: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleCreateAccount}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors, touched, isSubmitting, setErrors, setFieldValue }) => {
          const generateNewPassword = () => {
            const newPassword = generatePassword();
            setFieldValue('newPassword', newPassword);
            setFieldValue('confirmNewPassword', newPassword);
          };

          const clearErrorsAndMessages = () => {
            setErrors({});
          };

          return (
            <Form>
              <Stack spacing={4}>
                <Box position={'relative'}>
                  <Field
                    as={TextField}
                    fullWidth
                    label="First Name:"
                    id="firstName"
                    name="firstName"
                    autoComplete="email"
                    onFocus={clearErrorsAndMessages}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: errors.firstName ? 'red' : '',
                        borderWidth: errors.firstName ? '1.5px' : '',
                      },
                      '& .MuiFormLabel-root': {
                        color: errors.firstName ? 'red' : '',
                      },
                    }}
                  />
                  {touched.firstName && errors.firstName && <InputErrorField inputError={errors.firstName} />}
                </Box>
                <Box position={'relative'}>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Last Name:"
                    id="lastName"
                    name="lastName"
                    autoComplete="email"
                    onFocus={clearErrorsAndMessages}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: errors.lastName ? 'red' : '',
                        borderWidth: errors.lastName ? '1.5px' : '',
                      },
                      '& .MuiFormLabel-root': {
                        color: errors.lastName ? 'red' : '',
                      },
                    }}
                  />
                  {touched.lastName && errors.lastName && <InputErrorField inputError={errors.lastName} />}
                </Box>
                <Box position={'relative'}>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Email:"
                    id="newEmail"
                    name="newEmail"
                    autoComplete="email"
                    onFocus={clearErrorsAndMessages}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: errors.newEmail ? 'red' : '',
                        borderWidth: errors.newEmail ? '1.5px' : '',
                      },
                      '& .MuiFormLabel-root': {
                        color: errors.newEmail ? 'red' : '',
                      },
                    }}
                  />
                  {touched.newEmail && errors.newEmail && <InputErrorField inputError={errors.newEmail} />}
                </Box>
                <Box position={'relative'}>
                  <Field
                    as={TextField}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: errors.newPassword ? 'red' : '',
                        borderWidth: errors.newPassword ? '1.5px' : '',
                      },
                      '& .MuiFormLabel-root': {
                        color: errors.newPassword ? 'red' : '',
                      },
                    }}
                    type={showNewPassword ? 'text' : 'password'}
                    fullWidth
                    id="newPassword"
                    label="Enter New Password:"
                    name="newPassword"
                    onFocus={clearErrorsAndMessages}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                            <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {touched.newPassword && errors.newPassword && <InputErrorField inputError={errors.newPassword} />}
                </Box>
                <Box position={'relative'}>
                  <Field
                    as={TextField}
                    fullWidth
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirmNewPassword"
                    label="Confirm New Password:"
                    name="confirmNewPassword"
                    onFocus={clearErrorsAndMessages}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                            <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: errors.confirmNewPassword ? 'red' : '',
                        borderWidth: errors.confirmNewPassword ? '1.5px' : '',
                      },
                      '& .MuiFormLabel-root': {
                        color: errors.confirmNewPassword ? 'red' : '',
                      },
                    }}
                  />
                  {touched.confirmNewPassword && errors.confirmNewPassword && (
                    <InputErrorField inputError={errors.confirmNewPassword} />
                  )}
                </Box>
                <Box textAlign={'center'}>
                  <Button
                    variant="contained"
                    startIcon={<RefreshIcon sx={{ color: 'inherit' }} />}
                    onClick={generateNewPassword}
                    sx={{
                      textTransform: 'none',
                      backgroundColor: '#00D0FF',
                      color: 'black',
                      ':hover': {
                        fontWeight: 'bold',
                        backgroundColor: '#00D0D1',
                      },
                    }}
                  >
                    Generate New Password
                  </Button>
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  sx={{
                    my: 1,
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    bgcolor: '#4cbd29',
                    color: 'black',
                    ':hover': {
                      bgcolor: '#BEE6CE',
                      color: 'black',
                      fontWeight: 'bold',
                    },
                  }}
                  disabled={createAccountLoading || isSubmitting}
                >
                  {createAccountLoading || isSubmitting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Create Account'
                  )}
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
      <SnackbarNotification
        showNotification={openNotification}
        notificationHandler={notificationHandler}
        handleHideNotification={handleHideNotification}
      />
    </>
  );
};

export default CreateNewAccount;
