import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const AddNewLeadButtonStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,

  backgroundColor: '#4682B4',
  padding: '6px 16px',
  fontSize: '0.875rem',
  minWidth: '64px',
  boxShadow: `0 2px 4px ${theme.palette.grey[500]}`,
  transition: theme.transitions.create(['background-color', 'box-shadow', 'transform'], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }),
  '&:hover': {
    boxShadow: `0 4px 8px ${theme.palette.grey[700]}`,
    backgroundColor: '#87CEEB',
    transform: 'translateY(-1px)',
  },
  '&:active': {
    boxShadow: `0 1px 2px ${theme.palette.grey[700]}`,
    transform: 'translateY(1px)',
  },
  borderRadius: '8px',
  textTransform: 'none',
  letterSpacing: 'normal',
  fontWeight: 500,
}));

export const AddNewLeadButton = ({ handleOpenCreateLead }) => {
  return (
    <AddNewLeadButtonStyled
      sx={{
        mx: '6px',
        px: '8px',
      }}
      onClick={handleOpenCreateLead}
    >
      <PersonAddIcon
        color="white"
        fontSize="small"
        sx={{
          mr: '6px',
        }}
      />
      Add New Lead
    </AddNewLeadButtonStyled>
  );
};
