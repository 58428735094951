import React, { useState } from 'react';
import { Button, Popover, TextField, CircularProgress, Box } from '@mui/material';
import { Formik, Form, Field } from 'formik';
const NotePopover = ({ open, handleCloseMenu, editNote, handleSaveNote, handleUpdateNote }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [currentNoteText, setCurrentNoteText] = useState(editNote || '');
  const handleClickAway = () => {
    if (!currentNoteText) {
      handleCloseMenu();
    } else {
      setIsClosing(true);

      setTimeout(() => {
        setIsClosing(false);
      }, 150);
    }
  };
  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleClickAway}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      slotProps={{
        paper: {
          sx: {
            p: 1,
            width: 316,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        },
      }}
    >
      <Formik
        initialValues={{
          noteText: editNote || '',
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          try {
            if (editNote) {
              await handleUpdateNote(values.noteText);
            } else {
              await handleSaveNote(values.noteText);
            }
            resetForm();
          } catch (error) {
            console.error('Error saving note:', error);
          }
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Field
              as={TextField}
              sx={{ width: '300px', p: '0 !important' }}
              label={editNote ? 'Edit note' : 'Add note'}
              name="noteText"
              multiline
              rows={12}
              variant="outlined"
              onBlur={() => setCurrentNoteText(values.noteText)}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                sx={{
                  mt: '6px',
                  color: 'red',
                }}
                onClick={() => {
                  handleCloseMenu();
                  setCurrentNoteText('');
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  mt: '6px',
                  color: isClosing ? 'white' : 'green',
                  backgroundColor: isClosing ? 'green' : 'transparent',
                  transition: 'background-color 0.1s ease',
                }}
                type="submit"
                disabled={!values.noteText || isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : editNote ? 'Update note' : 'Save note'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Popover>
  );
};

export default NotePopover;
