// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          // Add the scrollbar styles here
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          // MS Edge scrollbar styles
          '&::-ms-scrollbar': {
            width: '12px',
          },
          '&::-ms-scrollbar-track': {
            background: '#f1f1f1',
          },
          '&::-ms-scrollbar-thumb': {
            background: '#888',
          },
          '&::-ms-scrollbar-thumb:hover': {
            background: '#555',
          },
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '.hoveredButton': {
          visibility: 'hidden',
        },
        '.hoveredButton > span': {
          display: 'flex',
        },
        '.phoneLink': {
          color: 'inherit',
          textDecoration: 'none',
        },
        '.tableCell': {
          padding: '2px 32px 2px 8px',
        },
        '.tableCell:hover .hoveredButton': {
          visibility: 'visible',
        },
        '.tableCell:hover .phoneLink': {
          color: 'green',
          textDecoration: 'underline',
        },
        '.css-1uoh5bq-MuiTableCell-root': {
          paddingTop: '0',
          paddingBottom: '0',
        },
        'MuiList-root.MuiList-padding.MuiMenu-list.css-r8u8y9': {
          paddingTop: '0',
          paddingBottom: '0',
        },
        '.css-1sp8c2x-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
          padding: '2px 32px 2px 14px!important',
        },
        ' .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
          padding: '2px 32px 2px 14px!important',
        },
        '.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list': {
          // Add the scrollbar styles for the ul list here
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '5px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        },
        ' .MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list': {
          paddingTop: '0!important',
          paddingBottom: '0!important',
        },

        '.modern-scrollbar': {
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '13px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#ccc',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#aaa',
          },
        },
        '.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.css-388y4j-MuiFormLabel-root-MuiInputLabel-root':
          {
            transform: 'translate(14px, 8px) scale(1)',
          },
        '.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.css-7nzas3': {
          transform: 'translate(14px, 8px) scale(1)',
        },
        '.MuiInputBase-input.MuiOutlinedInput-input': {
          padding: '8px 16px !important',
        },
        '.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart': {
          padding: '6.5px 0 !important',
        },
        '.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline': {
          padding: '0 !important',
        },
      }}
    />
  );

  return inputGlobalStyles;
}
