import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Slide,
  CircularProgress,
  Button,
  Checkbox,
  FormLabel,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Formik, Form, useFormikContext } from 'formik';
import { CancelButton, SaveButton } from './DriverCardModal';
import CloseModalIcon from '../globalCloseIcon/closeModalIcon';
import { displayDashboardColumns, TABLE_HEAD } from 'src/utils/dashboardColumnSettings';
import SnackbarNotification from './SnackBarNotification';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const accordionData = [
  {
    title: 'Personal Information',
    fields: ['Date', 'Name', 'Email', 'Phone', 'State', 'Status', 'Last Status Update', 'O/O', 'Model', 'Endorsements'],
  },
  {
    title: 'Driver Record',
    fields: [
      'Exp',
      'Note',
      'Violation',
      'Accident',
      'LP Experience',
      'Clearing house',
      'Drug Test',
      'DUI / SAP',
      'MVR',
      'Summary',
    ],
  },
  { title: 'Work Preferences', fields: ['Interested In', 'PTT', 'Truck Preferences', 'Home Time', 'Start Time'] },
  {
    title: 'Previous Employment',
    fields: ['Previous Company', 'Previous Employment', 'Previous Position', 'Why', 'Previous Miles', 'Previous Pay'],
  },
];

const DialogActionsWithFormik = ({ handleCloseDashBoardSettings }) => {
  const { isSubmitting, handleSubmit, resetForm } = useFormikContext();
  const handleReset = () => {
    resetForm({
      values: displayDashboardColumns,
    });
  };

  return (
    <DialogActions
      sx={{
        justifyContent: 'space-between',
      }}
    >
      <SaveButton type="submit" bgColor="rgb(2, 128, 144)" hoverBgColor="blue" onClick={handleReset} marginLeft="30px">
        Reset
      </SaveButton>
      <Box>
        <CancelButton
          onClick={() => {
            handleCloseDashBoardSettings(resetForm);
          }}
        >
          Close
        </CancelButton>
        <SaveButton type="submit" disabled={isSubmitting} isSubmitting={isSubmitting} onClick={handleSubmit}>
          {isSubmitting ? <CircularProgress size={24} /> : 'Save'}
        </SaveButton>
      </Box>
    </DialogActions>
  );
};

const SelectDashboardColumns = ({
  dataColumnsBooleanState,
  filterTableHead,
  setDisplayDataColumns,
  setDataColumnsBooleanState,
}) => {
  const [openDashboardSettings, setOpenDashboardSettings] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState({ panel0: true });
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationHandler, setNotificationHandler] = useState({
    title: '',
    severity: '',
  });
  const handleHideNotification = () => {
    setOpenNotification((prev) => !prev);
  };

  const handleAccordionChange = (panel) => (_, isExpanded) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [panel]: isExpanded,
    }));
  };
  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem('dashboardColumns', JSON.stringify(values));
    const filteredTableHead = filterTableHead(values, TABLE_HEAD);

    setDisplayDataColumns(filteredTableHead);
    setDataColumnsBooleanState(values);
    setSubmitting(false);
    setOpenDashboardSettings(false);
    setExpandedPanels({ panel0: true });
    setNotificationHandler({
      title: 'Column Update Successful',
      severity: 'success',
    });
    setOpenNotification(true);
  };
  const handleOpenDashBoardSettings = () => {
    setOpenDashboardSettings(true);
  };
  const handleCloseDashBoardSettings = (resetForm) => {
    const dashboardColumns = JSON.parse(localStorage.getItem('dashboardColumns'));
    resetForm({ values: dashboardColumns });
    setDataColumnsBooleanState(dashboardColumns);
    setOpenDashboardSettings(false);
    setExpandedPanels({ panel0: true });
  };
  return (
    <>
      <Button
        sx={{
          width: '60%',
          textTransform: 'capitalize',
          backgroundColor: 'rgb(255,247,205)',
          mx: '2px',
          my: '2px',
          color: 'rgb(2, 128, 144)',
          '&:hover': {
            color: 'red',
            backgroundColor: 'rgba(255,247,205,0.5)',
          },
        }}
        onClick={handleOpenDashBoardSettings}
      >
        Edit Columns
      </Button>
      <Formik initialValues={dataColumnsBooleanState} onSubmit={handleSubmit} enableReinitialize>
        {({ values, handleChange, resetForm }) => {
          const checkedCount = Object.values(values).filter(Boolean).length;

          return (
            <Form style={{ display: 'flex', flexDirection: 'column' }}>
              <Dialog
                open={openDashboardSettings}
                TransitionComponent={Transition}
                PaperProps={{
                  sx: {
                    width: '30%',
                    maxWidth: 'none',
                    height: '100%',
                    overflow: 'visible',
                    position: 'relative',
                  },
                }}
                onClose={() => {
                  handleCloseDashBoardSettings(resetForm);
                }}
                aria-describedby="alert-dialog-slide-description"
              >
                <CloseModalIcon handleClose={handleCloseDashBoardSettings} resetForm={resetForm} />
                <Box
                  sx={{
                    py: '6px',
                    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    zIndex: 1,
                  }}
                >
                  <DialogTitle
                    sx={{
                      fontSize: '24px !important',
                      py: '6px',
                      textAlign: 'center',
                      textTransform: 'capitalize',
                    }}
                  >
                    Select Visible Columns
                  </DialogTitle>
                </Box>
                <DialogContent
                  className="modern-scrollbar"
                  sx={{
                    flex: 1,
                    overflowY: 'auto',
                    p: '20px 40px',
                  }}
                >
                  {accordionData.map((accordion, index) => (
                    <Accordion
                      key={index}
                      expanded={!!expandedPanels[`panel${index}`]}
                      onChange={handleAccordionChange(`panel${index}`)}
                      sx={{
                        '&:not(:last-child)': {
                          borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                        },
                        '&.Mui-expanded': {
                          my: 0,
                        },
                        '&::before': {
                          display: 'none',
                        },
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          '&.Mui-expanded': {
                            minHeight: '48px',
                          },
                          '&.Mui-expanded .MuiAccordionSummary-content': {
                            margin: 0,
                          },
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography
                          sx={{
                            fontWeight: '500',
                          }}
                        >
                          {accordion.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          p: '0 8px 8px 16px',
                        }}
                      >
                        {accordion.fields.map((accordionElement) => (
                          <Box
                            key={accordionElement}
                            sx={{
                              marginBottom: '4px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <FormLabel>{accordionElement}</FormLabel>
                            <Checkbox
                              sx={{
                                mr: 0,
                                '&.Mui-checked': {
                                  color: 'rgb(2, 128, 144)',
                                },
                                '&.Mui-disabled': {
                                  color: 'rgba(0, 0, 0, 0.26)',
                                },
                              }}
                              checked={values[accordionElement] === true}
                              disabled={checkedCount === 6 && values[accordionElement]}
                              onChange={(event) =>
                                handleChange({ target: { name: accordionElement, value: event.target.checked } })
                              }
                            />
                          </Box>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </DialogContent>
                <DialogActionsWithFormik handleCloseDashBoardSettings={handleCloseDashBoardSettings} />
              </Dialog>
            </Form>
          );
        }}
      </Formik>
      <SnackbarNotification
        showNotification={openNotification}
        notificationHandler={notificationHandler}
        handleHideNotification={handleHideNotification}
      />
    </>
  );
};

export default SelectDashboardColumns;
