import React from 'react';
import { Typography } from '@mui/material';

const InputErrorField = ({ inputError, left = '4px', top = '100%', right = 0, color = 'red' }) => {
  return (
    <>
      {inputError && (
        <Typography
          sx={{
            position: 'absolute',
            top: top,
            left: left,
            right: right,
            fontSize: '12px',
            color: color,
            textAlign: 'left',
          }}
        >
          {inputError}
        </Typography>
      )}
    </>
  );
};

export default InputErrorField;
