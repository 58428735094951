import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import RecruiterSelect from './RecruiterSelect';
import CloseModalIcon from '../globalCloseIcon/closeModalIcon';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteRecruiterAccount = ({
  closeDeleteAccount,
  deleteAccountLoading,
  handleDeleteAccount,
  openDeleteAccount,
  currentRecruiter,
  handleRecruiterChange,
  allRecruiters,
}) => {
  const [deleteAccountCheck, setDeleteAccountCheck] = useState('');
  const { recruiter_full_name, recruiter_id } = currentRecruiter;

  const handleCloseDeteleAccount = () => {
    closeDeleteAccount();
    setDeleteAccountCheck('');
  };

  const onDeleteRecruiterAccount = () => {
    handleDeleteAccount();
    setDeleteAccountCheck('');
  };

  const canDelete = deleteAccountCheck.toLowerCase() === 'delete account';
  return (
    <Dialog
      open={openDeleteAccount}
      TransitionComponent={Transition}
      PaperProps={{ sx: { width: '50%', maxWidth: 'none', overflow: 'visible', position: 'relative' } }}
      onClose={handleCloseDeteleAccount}
      aria-describedby="alert-dialog-slide-description"
    >
      <CloseModalIcon handleClose={handleCloseDeteleAccount} />
      <DialogTitle
        sx={{
          fontSize: '24px !important',
          py: '6px',
          textAlign: 'center',
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: '24px !important',
            fontWeight: 'bold',
          }}
        >
          Are you absolutely sure?
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          p: '30px !important',
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: '600',
            color: 'red',
            mb: '12px',
          }}
        >
          You are about to delete {recruiter_full_name}'s account.
        </Typography>
        {allRecruiters.length > 1 ? (
          <>
            <Typography mb={2}>
              This action is not reversible. Choose another recruiter to move the leads to (if left blank only you will
              be able to see this account current leads):
            </Typography>
            <RecruiterSelect
              onChange={handleRecruiterChange}
              allRecruiters={allRecruiters}
              selectedRecruiter={recruiter_id}
              width="50%"
              isDeleting
            />
          </>
        ) : (
          <Typography mb={2}>This action is not reversible.</Typography>
        )}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Typography variant="body1">Enter the following to confirm:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'inline-block',
                bgcolor: '#e0e0e0',
                p: '8px 12px',
                borderRadius: '4px',
                fontFamily: 'monospace',
                fontSize: '14px',
              }}
            >
              delete account
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={deleteAccountCheck}
              onChange={(e) => setDeleteAccountCheck(e.target.value)}
              sx={{
                mb: '14px',
              }}
              inputProps={{
                sx: {
                  padding: '2px 10px',
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeteleAccount} color="inherit" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={onDeleteRecruiterAccount}
          disabled={deleteAccountLoading || !canDelete}
          color="error"
          variant="contained"
          sx={{
            minWidth: '86px',
          }}
        >
          {deleteAccountLoading ? <CircularProgress size={24} /> : 'Delete Account'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRecruiterAccount;
