export const updateStatusOnServer = async (leadId, companyId, status, recruiterId) => {
  const response = await fetch('https://app.truckersflow.com/wp-json/data-api/v1/status', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:
        'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
    },
    body: JSON.stringify({
      lead_id: leadId,
      company_id: companyId,
      status: status,
      recruiter_id: recruiterId,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'An error happened');
  }
  return data;
};

export const saveNoteOnServer = async (leadID, companyID, noteText, recruiterId) => {
  const response = await fetch('https://app.truckersflow.com/wp-json/data-api/v1/note', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:
        'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
    },
    body: JSON.stringify({
      lead_id: leadID,
      company_id: companyID,
      note: noteText,
      recruiter_id: recruiterId,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'An error happened');
  }
  return data;
};

export const handleNoteAfterSave = async (leadID, companyID, noteText) => {
  const response = await fetch('https://app.truckersflow.com/wp-json/data-api/v1/note-notify', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:
        'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
    },
    body: JSON.stringify({
      lead_id: leadID,
      company_id: companyID,
      note: noteText,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'An error happened');
  }
  return data;
};

export const updateNoteOnServer = async (leadID, companyID, noteID, noteText, recruiterId) => {
  const response = await fetch('https://app.truckersflow.com/wp-json/data-api/v1/note', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:
        'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
    },
    body: JSON.stringify({
      note_id: noteID,
      lead_id: leadID,
      company_id: companyID,
      note: noteText,
      recruiter_id: recruiterId,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'An error happened');
  }
  return data;
};

export const handleNoteAfterUpdate = async (leadID, companyID, noteText) => {
  const response = await fetch('https://app.truckersflow.com/wp-json/data-api/v1/note-notify', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:
        'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
    },
    body: JSON.stringify({
      lead_id: leadID,
      company_id: companyID,
      note: noteText,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'An error happened');
  }
  return data;
};

export const deleteNoteOnServer = async (noteID, leadID, companyID, recruiterId) => {
  const response = await fetch(`https://app.truckersflow.com/wp-json/data-api/v1/note`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:
        'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
    },
    body: JSON.stringify({
      note_id: noteID,
      lead_id: leadID,
      company_id: companyID,
      recruiter_id: recruiterId,
    }),
  });
  const data = await response.json();
  if (response.ok) {
  } else {
    // Handle the error scenario
    console.error('Error with the request');
  }
};
