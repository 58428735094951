import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  CircularProgress,
  Button,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { UserContext } from '../../../context/userContext';
import useLoginAuth from '../../../hooks/useLoginAuth';
// components
import Iconify from '../../../components/iconify';
import InputErrorField from './InputErrorField';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { setIsLoggedIn, setUser, setFetchingDataLoader } = useContext(UserContext);

  const [logInEmail, setLogInEmail] = useState('');
  const [logInPassword, setLogInPassword] = useState('');
  const [logInLoading, setLogInLoading] = useState(false);

  const [logInEmailError, setLogInEmailError] = useState('');
  const [logInPasswordError, setLogInPasswordError] = useState('');
  const [catchLoginError, setCatchLoginError] = useState('');

  const handleEmailInputChange = (e) => {
    setLogInEmail(e.target.value);
  };

  const handleLogInPasswordInputChange = (e) => {
    setLogInPassword(e.target.value);
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    navigate('/dashboard', { replace: true });
  };

  const handleLogInSubmit = (e) => {
    setCatchLoginError('');
    e.preventDefault();
    if (!logInEmail || logInEmail.length === 0) {
      setLogInEmailError('* This field is required!');
    } else if (!logInEmail.match(/^\S+@\S+\.\S+$/) || logInEmail.match(/[#$%&!*]/)) {
      setLogInEmailError('* Please enter a valid email address!');
    } else if (!logInPassword || logInPassword.length === 0) {
      setLogInPasswordError('* This field is required!');
    } else {
      setLogInLoading(true);
      useLoginAuth({
        setIsLoggedIn,
        setUser,
        logInEmail,
        logInPassword,
        setLogInLoading,
        setCatchLoginError,
        setFetchingDataLoader,
      });
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Box position={'relative'}>
          <TextField
            fullWidth
            label="Email"
            id="logIn_email"
            name="logIn_email"
            autoComplete="email"
            value={logInEmail}
            onChange={handleEmailInputChange}
            onFocus={() => {
              setLogInEmailError('');
              setCatchLoginError('');
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: logInEmailError ? 'red' : '',
                borderWidth: logInEmailError ? '1.5px' : '',
              },
              '& .MuiFormLabel-root': {
                color: logInEmailError ? 'red' : '',
              },
            }}
          />
          <InputErrorField inputError={logInEmailError} />
        </Box>

        <Box position={'relative'}>
          <TextField
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="password"
            value={logInPassword}
            onChange={handleLogInPasswordInputChange}
            onFocus={() => {
              setLogInPasswordError('');
              setCatchLoginError('');
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: logInPasswordError ? 'red' : '',
                borderWidth: logInPasswordError ? '1.5px' : '',
              },
              '& .MuiFormLabel-root': {
                color: logInPasswordError ? 'red' : '',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <InputErrorField inputError={logInPasswordError} />
          <InputErrorField inputError={catchLoginError} />
        </Box>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        <Link variant="subtitle2" href="/identify" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      {/* <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton> */}
      <Button
        type="submit"
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleLogInSubmit}
        sx={{
          my: '1',
          fontWeight: 'bold',
          textTransform: 'capitalize',

          ':hover': {
            bgcolor: '#BEE6CE',
            color: 'black',
            fontWeight: 'bold',
          },
        }}
      >
        {logInLoading ? <CircularProgress size={24} color="inherit" /> : 'Sign in'}
      </Button>
    </>
  );
}
