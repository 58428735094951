const useDeleteLead = async (leadId) => {
  const response = await fetch(`https://app.truckersflow.com/wp-json/data-api/v1/delete-lead`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:
        'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
    },
    body: JSON.stringify(leadId),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'An error happened');
  }
  return data;
};

export default useDeleteLead;
