import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import { StyledAccount } from '../../layouts/dashboard/nav';

const GeneralSettings = ({ user }) => {
  return (
    <Grid item xs={12}>
      <Typography>Manage settings for {user.company_name}</Typography>

      <Divider sx={{ borderStyle: 'dashed', my: 3, borderBlockWidth: '1px' }} />

      <StyledAccount>
        <Avatar sizes="250px" src={user.company_full_logo} alt="photoURL" />

        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {user.company_name}
          </Typography>
        </Box>
      </StyledAccount>

      <Divider sx={{ borderStyle: 'dashed', my: 3, borderBlockWidth: '1px' }} />
    </Grid>
  );
};

export default GeneralSettings;
