import { styled } from '@mui/material/styles';
import { Toolbar, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import useGetLeadsBySearch from 'src/hooks/useGetLeadsBySearch';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  padding: theme.spacing(0, 1, 0, 0),
  flexWrap: 'wrap',
  marginBottom: '10px',
}));

const buttonOptions = [
  { label: 'all', value: 'all' },
  { label: 'new', value: 'new' },
  { label: 'attempting contact', value: 'attempting contact' },
  { label: 'follow up', value: 'follow up' },
  { label: 'processing', value: 'processing' },
  { label: 'approved', value: 'approved' },
  { label: 'hired', value: 'hired' },
  { label: 'no experience', value: 'pass/not enough experience' },
  { label: 'no response', value: 'no response' },
  { label: 'rejected', value: 'rejected' },
];

export default function UserListStatus({
  user,
  onStatusToolbarClick,
  currentFilter,
  currentSourceId,
  setAllDrivers,
  setFetchingDataLoader,
  rowsPerPage,
  setTotalLeads,
  filterByRecruiter,
  filterByPosition,
}) {
  const [selectedButton, setSelectedButton] = useState({ label: 'all', value: 'all' });

  useEffect(() => {
    setSelectedButton({ label: 'all', value: 'all' });
  }, [currentFilter]);

  return (
    <>
      <StyledRoot sx={{ px: '4px!important' }}>
        {buttonOptions.map((button, idx) => (
          <Button
            key={idx}
            sx={{
              textTransform: 'capitalize',
              backgroundColor: selectedButton.label === button.label ? 'rgba(32, 101, 209, 0.08)' : 'transparent',
              mx: '2px',
              my: '2px',
              color: 'rgb(2, 128, 144)',
            }}
            onClick={(event) => {
              if (event.currentTarget.innerText.toLowerCase() === selectedButton.label) return;
              onStatusToolbarClick(event);
              setSelectedButton(button);
              setAllDrivers([]);
              setFetchingDataLoader(false);

              const searchParams = {
                companyId: user.company_id,
                leadSource: currentSourceId,
                currentStatus: button.value,
                recruiterId: user.recruiter_id ?? filterByRecruiter,
                rowsPerPage,
                filteredPosition: filterByPosition,
              };

              useGetLeadsBySearch(searchParams)
                .then((data) => {
                  const finalLeads = Array.isArray(data.lead_list)
                    ? data.lead_list.map((lead) => {
                        return {
                          ...lead,
                          lead_status: data.status_list[lead.lead_id].status || 'not contacted',
                          driverNotes: data.notes_list[lead.lead_id] || [],
                          lead_status_modified: data.status_list[lead.lead_id].status_modified,
                        };
                      })
                    : [];

                  setTotalLeads(data.total_leads);
                  setAllDrivers(finalLeads);
                })
                .catch((error) => {
                  console.error('Something went wrong', error);
                })
                .finally(() => {
                  setFetchingDataLoader(true);
                });
            }}
          >
            {button.label}
          </Button>
        ))}
      </StyledRoot>
    </>
  );
}
