import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';

const RecruiterSelect = ({
  allRecruiters,
  selectedRecruiter,
  onChange,
  isDeleting = false,
  width = '100%',
  filterBy,
  generalFetchLeads,
}) => {
  // console.log(allRecruiters);

  const [recruiterId, setRecruiterId] = useState(isDeleting ? '' : selectedRecruiter || '');

  useEffect(() => {
    if (!selectedRecruiter) return;
    setRecruiterId(isDeleting ? '' : selectedRecruiter);
  }, [selectedRecruiter]);

  const handleRecruiterChange = (event) => {
    const newSelectedRecruiter = event.target.value;
    onChange?.(newSelectedRecruiter);
    setRecruiterId(newSelectedRecruiter);
    if (filterBy) {
      generalFetchLeads({ changedRecruiter: newSelectedRecruiter });
    }
  };
  const availableRecruiters = allRecruiters.filter(
    (recruiter) => recruiter.recruiter_status !== 'disabled' && recruiter.recruiter_status !== 'deleted'
  );

  return (
    <>
      {availableRecruiters.length > 1 && (
        <FormControl variant="outlined" fullWidth sx={{ width: width, p: 0, display: 'block' }}>
          <Select
            labelId="demo-simple-select-label"
            id="recruiter-select"
            fullWidth
            displayEmpty
            value={recruiterId}
            onChange={handleRecruiterChange}
            renderValue={(selected) => {
              if (!selected) {
                return <em>{filterBy ? 'Filter by recruiter' : 'Select a recruiter'}</em>;
              }
              return (
                allRecruiters.find((recruiter) => String(recruiter.recruiter_id) === selected)?.recruiter_full_name ||
                ''
              );
            }}
          >
            {filterBy && (
              <MenuItem
                sx={{
                  bgcolor: 'lightblue',
                }}
                value=""
              >
                All Recruiters
              </MenuItem>
            )}
            {allRecruiters
              .filter(
                (recruiter) =>
                  recruiter.recruiter_id !== Number(selectedRecruiter) &&
                  recruiter.recruiter_status !== 'disabled' &&
                  recruiter.recruiter_status !== 'deleted'
              )
              .map((recruiter) => (
                <MenuItem key={recruiter.recruiter_id} value={String(recruiter.recruiter_id)}>
                  {recruiter.recruiter_full_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default RecruiterSelect;
