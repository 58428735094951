export const getYesNoFromString = (metaValue, fallbackValue) => {
  if (metaValue?.toLowerCase().startsWith('yes') || metaValue?.toLowerCase() === 'true') {
    return 'Yes';
  } else if (metaValue?.toLowerCase().startsWith('no') || metaValue?.toLowerCase() === 'false') {
    return 'No';
  } else if (fallbackValue?.toLowerCase().startsWith('yes') || fallbackValue?.toLowerCase() === 'true') {
    return 'Yes';
  } else if (fallbackValue?.toLowerCase().startsWith('no') || fallbackValue?.toLowerCase() === 'false') {
    return 'No';
  } else {
    return '---';
  }
};
