import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { Container } from '@mui/system';

import Iconify from '../components/iconify';

import InputErrorField from '../sections/auth/login/InputErrorField';
import Logo from '../components/logo';
import { UserContext } from 'src/context/userContext';
import ResetModals from 'src/components/resetPassModals/ResetModals';
import { generatePassword } from '../helpers/GeneratePassword';

const ResetPassword = () => {
  const { resetToken } = useContext(UserContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Error message constants

  const [new_password_error, setNew_password_error] = useState('');
  const [confirm_password_error, setConfirm_password_error] = useState('');

  // Error message constants XXX

  const [passwordCheckMatch, setPasswordCheckMatch] = useState(false);

  // Boolean show / hide states

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Boolean show / hide states XXX

  // Modal information states

  const [modalDisplayMessage, setModalDisplayMessage] = useState('');

  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [changePasswordFailure, setChangePasswordFailure] = useState(false);

  // Modal information states XXX

  // Query states - key and user

  const [userName, setUserName] = useState('');
  const [userKey, setUserKey] = useState('');

  useEffect(() => {
    generateNewPassword();
  }, []);

  useEffect(() => {
    if (
      newPassword.length > 5 &&
      /[A-Z]/.test(newPassword) &&
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword) &&
      newPassword === confirmNewPassword
    ) {
      setPasswordCheckMatch(true);
    } else {
      setPasswordCheckMatch(false);
    }
  }, [newPassword, confirmNewPassword]);

  useEffect(() => {
    const storedUserName = sessionStorage.getItem('userName');
    const storedUserKey = sessionStorage.getItem('userKey');

    if (storedUserName) {
      setUserName(storedUserName);
    }

    if (storedUserKey) {
      setUserKey(storedUserKey);
    }
  }, []);
  useEffect(() => {
    try {
      if (resetToken) {
        const name = resetToken.split('=')[2];
        const key = resetToken.split('=')[1].split('&')[0];

        if (!name || !key) {
          throw new Error('Invalid token format');
        }

        setUserName(name);
        setUserKey(key);

        sessionStorage.setItem('userName', name);
        sessionStorage.setItem('userKey', key);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      navigate('/404'); // Redirect to 404 page
    }
  }, [resetToken, navigate]);

  const generateNewPassword = () => {
    const newPassword = generatePassword();
    setNewPassword(newPassword);
    setNew_password_error('');
    setConfirm_password_error('');
    setConfirmNewPassword(newPassword);
  };

  // Handle Password Blur

  const handleNewPassBlur = (e) => {
    if (!e.target.value || e.target.value.length === 0) {
      setNew_password_error('* This field is required!');
    } else if (e.target.value.length < 6) {
      setNew_password_error('* Password must contain at least 6 characters!');
    } else if (!e.target.value.match(/[A-Z]/)) {
      setNew_password_error('* Password must contain at least one uppercase letter!');
    } else if (!e.target.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
      setNew_password_error('* Password must contain at least one special character!');
    } else {
      setNew_password_error('');
    }
    if (e.target.value !== confirmNewPassword) {
      setConfirm_password_error('* Password must match!');
    }
  };

  const handleConfirmPassBlur = (e) => {
    if (e.target.value !== newPassword) {
      setConfirm_password_error('* Password must match!');
    } else {
      setConfirm_password_error('');
    }
  };

  // Handle Password Blur XXX

  const handlePasswordResetRequest = () => {
    setIsLoading(true);

    fetch(`https://app.truckersflow.com/wp-json/data-api/v1/resetpassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization:
          'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
      },
      body: JSON.stringify({
        key: userKey,
        login: userName,
        password: newPassword,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorResponse = await response.json();
          setModalDisplayMessage(errorResponse.message || 'Unfortunately something went wrong! Try again later');
          setChangePasswordFailure(true);
          throw new Error('Server responded with an error: ' + errorResponse.message);
        }
        return response.json();
      })
      .then(() => {
        setModalDisplayMessage('You have successfully changed your password. Log in now!');
        setChangePasswordSuccess(true);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setChangePasswordFailure(true);

        setModalDisplayMessage('Unfortunately something went wrong! Try again later');
      })
      .finally(() => {
        setIsLoading(false);
        setNewPassword('');
        setConfirmNewPassword('');

        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('userKey');
      });
  };

  return (
    <>
      <Box className="container">
        <Logo
          sx={{
            justifyContent: 'center',
          }}
        />
        <Grid
          container
          spacing={0}
          mx="auto"
          sx={{ pb: '40px' }}
          minHeight="calc(100vh - 64px)"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10} md={8}>
            <Box
              sx={{
                height: '100%',
                backgroundColor: 'white',
                boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
                borderRadius: '10px',
                width: '100%',
              }}
            >
              <Container
                component="main"
                maxWidth="md"
                sx={{
                  p: '6px 0 50px 0',
                  width: '90%',
                  m: '0 auto',
                  textAlign: 'center',
                }}
              >
                <Grid item xs={12} md={12} textAlign={'center'}>
                  <Typography
                    variant={isMobile ? 'h5' : 'h4'}
                    my="15px"
                    sx={{
                      fontWeight: 'bold',
                      color: '#028090',
                    }}
                  >
                    Change your password
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: isMobile ? '14px' : '18px',
                    }}
                  >
                    Enter a new password below to change your password
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={2} position={'relative'}>
                  <TextField
                    sx={{
                      mt: '40px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: new_password_error ? 'red' : '',
                        borderWidth: new_password_error ? '1.5px' : '',
                      },
                      '& .MuiFormLabel-root': {
                        color: new_password_error ? 'red' : '',
                      },
                    }}
                    type={showNewPassword ? 'text' : 'password'}
                    fullWidth
                    id="new_driver_password"
                    label="Enter New Password:"
                    name="new_driver_password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    onFocus={() => {
                      setNew_password_error('');
                    }}
                    onBlur={handleNewPassBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                            <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <InputErrorField inputError={new_password_error} />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Button
                    variant="contained"
                    startIcon={<RefreshIcon sx={{ color: 'inherit' }} />}
                    onClick={generateNewPassword}
                    sx={{
                      marginTop: '18px',
                      textTransform: 'none',
                      width: !isMobile ? '35%' : '100%',
                    }}
                  >
                    Generate New Password
                  </Button>
                </Grid>
                <Grid item xs={12} mb={2} position={'relative'}>
                  <TextField
                    fullWidth
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirm_new_password"
                    label="Confirm New Password:"
                    name="confirm_new_password"
                    value={confirmNewPassword}
                    onChange={(e) => {
                      setConfirmNewPassword(e.target.value);
                    }}
                    onFocus={() => {
                      setConfirm_password_error('');
                    }}
                    onBlur={handleConfirmPassBlur}
                    sx={{
                      mt: '20px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: confirm_password_error ? 'red' : '',
                        borderWidth: confirm_password_error ? '1.5px' : '',
                      },
                      '& .MuiFormLabel-root': {
                        color: confirm_password_error ? 'red' : '',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                            <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <InputErrorField inputError={confirm_password_error} />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Button
                    type="button"
                    fullWidth={isMobile}
                    disabled={!passwordCheckMatch}
                    color="primary"
                    variant="contained"
                    onClick={handlePasswordResetRequest}
                    sx={{
                      width: !isMobile ? '35%' : '100%',
                      mt: 3,
                      mb: 2,
                      fontWeight: 'bold',
                      textTransform: 'none',
                      ':hover': {
                        bgcolor: '#BEE6CE',
                        color: 'black',
                        fontWeight: 'bold',
                      },
                    }}
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Change Password'}
                  </Button>
                </Grid>
              </Container>
            </Box>{' '}
          </Grid>
        </Grid>
        <ResetModals
          callFailedTrigger={changePasswordFailure}
          setCallFailedTrigger={setChangePasswordFailure}
          callSucceededTrigger={changePasswordSuccess}
          setCallSucceededTrigger={setChangePasswordSuccess}
          displayMessage={modalDisplayMessage}
        />
      </Box>
    </>
  );
};

export default ResetPassword;
