import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputErrorField from '../sections/auth/login/InputErrorField';
import Logo from '../components/logo';
import ResetModals from 'src/components/resetPassModals/ResetModals';

const formTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#028090', // Change the border color
            borderWidth: '2px', // Change the border width
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#028090', // Change the border color on hover
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-focused': {
            '& .MuiInputBase-input': {
              borderColor: '#028090', // Change the border color on focus
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#028090',
          '&:hover': {
            backgroundColor: '#028090',
          },
        },
        outlined: {
          border: '1px solid #028090',
          color: '#028090',
          '&:hover': {
            border: '1px solid #028090',
            backgroundColor: '#028090',
            color: '#fff',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.css-14lo706': {
            color: '#028090', // Change the color of the legend
          },
          '&.Mui-focused': {
            color: '#028090', // Change the label color when focused
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#028090', // Change the color of the Visibility icon
        },
      },
    },
  },
});

export default function IdentifyPage() {
  const navigate = useNavigate();

  const [resetUserEmail, setResetUserEmail] = useState('');
  const [resetUserEmailError, setResetUserEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalDisplayMessage, setModalDisplayMessage] = useState('');

  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [resetPasswordFailure, setResetPasswordFailure] = useState(false);

  const handleResetRequest = () => {
    if (resetUserEmail && resetUserEmail.match(/^\S+@\S+\.\S+$/)) {
      setIsLoading(true);
      fetch(`https://app.truckersflow.com/wp-json/data-api/v1/resetpasswordkey`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization:
            'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
        },
        body: JSON.stringify({
          driver_login: resetUserEmail,
          site_url: 'https://truckersflow.app',
        }),
      })
        .then((response) => {
          if (!response.ok) {
            setResetUserEmailError('Network Error');
            setModalDisplayMessage('Oh no, something went wrong! Try again later or contact our support!');
          }

          return response.json();
        })
        .then((valid) => {
          setResetUserEmailError('');
          setResetPasswordSuccess(true);
          setModalDisplayMessage(`A password reset link has been sent to your mail ${resetUserEmail}!`);
        })
        .catch(() => {
          setResetPasswordFailure(true);
          setModalDisplayMessage('Oh no, something went wrong! Try again later or contact our support!');
        })
        .finally(() => {
          setIsLoading(false);
        });

      setResetUserEmailError('');
    } else {
      setResetUserEmailError('* Please insert a valid email');
    }
  };
  return (
    <>
      <Box className="container">
        <Logo
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        />

        <Grid
          container
          spacing={5}
          justifyContent="center"
          alignItems="center"
          sx={{
            px: {
              xs: 0,
              lg: '72.838px',
            },
            minHeight: 'calc(100vh - 128px)',
          }}
        >
          <Grid item xs={10} md={6}>
            <Box
              sx={{
                height: '100%',
                mx: 'auto',
                bgcolor: 'white',
                boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
                padding: '10px',
                borderRadius: '10px',
              }}
            >
              <Container
                component="main"
                maxWidth="md"
                sx={{
                  p: { xs: '10px 0', md: '6px 0 10px 0' },
                  width: '90%',
                  m: '0 auto',
                }}
              >
                <ThemeProvider theme={formTheme}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '22px',
                    }}
                  >
                    Forgot password?
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                    }}
                  >
                    Reset your password in two quick steps.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} position={'relative'}>
                      <TextField
                        fullWidth
                        id="driver_email"
                        label="Email Address"
                        name="driver_email"
                        autoComplete="email"
                        value={resetUserEmail}
                        onChange={(e) => {
                          setResetUserEmail(e.target.value);
                        }}
                        onFocus={() => {
                          setResetUserEmailError('');
                        }}
                        sx={{
                          mt: '40px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: resetUserEmailError ? 'red' : '',
                            borderWidth: resetUserEmailError ? '1.5px' : '',
                          },
                          '& .MuiFormLabel-root': {
                            color: resetUserEmailError ? 'red' : '',
                          },
                        }}
                      />
                      <InputErrorField inputError={resetUserEmailError} left="20px" />
                    </Grid>
                  </Grid>

                  <Button
                    type="button"
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={handleResetRequest}
                    sx={{
                      mt: 3,
                      mb: 2,
                      fontWeight: 'bold',
                      textTransform: 'none',
                      ':hover': {
                        bgcolor: '#BEE6CE',
                        color: 'black',
                        fontWeight: 'bold',
                      },
                    }}
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Reset password'}
                  </Button>
                  <Box display="flex" justifyContent="center">
                    <Button
                      component="button"
                      variant="text"
                      color="primary"
                      sx={{
                        display: '',
                        fontWeight: '500',
                        color: '#028090',
                        textTransform: 'capitalize',
                        ':hover': {
                          bgcolor: 'transparent',
                          color: 'red',
                          fontWeight: 'bold',
                        },
                      }}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                </ThemeProvider>
              </Container>
            </Box>
          </Grid>
        </Grid>
        <ResetModals
          callFailedTrigger={resetPasswordFailure}
          setCallFailedTrigger={setResetPasswordFailure}
          callSucceededTrigger={resetPasswordSuccess}
          setCallSucceededTrigger={setResetPasswordSuccess}
          displayMessage={modalDisplayMessage}
        />
      </Box>
    </>
  );
}
