import React from 'react';
import { Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const PrevNextButtons = ({ handleLeadChange, isNextDisabled, isPreviousDisabled }) => {
  return (
    <>
      <Button
        sx={{
          position: 'absolute',
          left: '8px',
          top: '50%',
          p: 0,
          minWidth: '24px',
        }}
        disabled={isPreviousDisabled}
        onClick={() => {
          handleLeadChange('-');
        }}
      >
        <ArrowBackIosNewIcon />
      </Button>
      <Button
        sx={{
          position: 'absolute',
          right: '8px',
          top: '50%',
          p: 0,
          minWidth: '24px',
        }}
        disabled={isNextDisabled}
        onClick={() => {
          handleLeadChange('+');
        }}
      >
        <ArrowForwardIosIcon />
      </Button>
    </>
  );
};

export default PrevNextButtons;
