import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';

const CloseModalIcon = ({ handleClose, resetForm, top = '-15px', right = '-15px' }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={() => {
        resetForm ? handleClose(resetForm) : handleClose();
      }}
      sx={{
        cursor: 'pointer',
        position: 'absolute',
        top: { top },
        right: { right },
        zIndex: 9999,
        p: 0,
        color: 'red',
        background: 'radial-gradient(circle , transparent 0%, white 0%, white 50%, transparent 60%)',
        borderRadius: '50%',
        '&:hover': {
          color: 'white',
          background: 'radial-gradient(circle , transparent 0%, red 0%, red 50%, transparent 80%)',
        },
      }}
    >
      <CancelIcon fontSize="large" />
    </IconButton>
  );
};

export default CloseModalIcon;
