import React from 'react';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';

const CustomToolTip = ({ toolTipTitle, children, placement, offset }) => {
  return (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: offset ?? [0, 10],
            },
          },
        ],
      }}
      TransitionComponent={Zoom}
      placement={placement ?? 'left'}
      title={toolTipTitle}
      arrow
    >
      {children}
    </Tooltip>
  );
};

export default CustomToolTip;
