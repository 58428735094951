const useGetLeadsBySearch = async ({
  companyId,
  leadSource = -1,
  currentStatus = 'all',
  searchTerm = '',
  page = 0,
  rowsPerPage = 50,
  recruiterId,
  filteredPosition = 'all',
}) => {
  const requestBody = {
    company_id: companyId,
    lead_source: leadSource,
    status: currentStatus,
    search_term: searchTerm,
    leads_per_page: rowsPerPage,
    page: page + 1,
    driver_position: filteredPosition,
  };

  recruiterId && (requestBody.recruiter_id = recruiterId);
  const response = await fetch(`https://app.truckersflow.com/wp-json/data-api/v1/allleads`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:
        'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
    },
    body: JSON.stringify(requestBody),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'An error happened');
  }
  return data;
};

export default useGetLeadsBySearch;
