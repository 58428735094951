import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export { dayjs, utc, timezone, customParseFormat };

export const fromUTCtoTimezone = (inputTime, inputFormat = 'MM/DD/YYYY hh:mm A') => {
  const guessedTimezone = dayjs.tz.guess();
  const utcDate = dayjs.utc(inputTime, inputFormat);
  const localDate = utcDate.tz(guessedTimezone);
  const formattedDate = localDate.format('MM/DD/YYYY hh:mm A');

  return formattedDate;
};

export const currentDateToTimezone = () => {
  const utcCurrentDate = dayjs.utc();
  return utcCurrentDate.format('MM/DD/YYYY hh:mm A');
};

export const calculateDaysAgo = (date) => {
  const targetDate = dayjs(date);
  const currentDate = dayjs(fromUTCtoTimezone(currentDateToTimezone()));
  const daysDifference = currentDate.diff(targetDate, 'day');

  if (daysDifference === 0) {
    return 'Today';
  } else if (daysDifference === 1) {
    return 'Yesterday';
  } else {
    return `${daysDifference} days ago`;
  }

  // const yearsDifference = currentDate.diff(targetDate, 'year');
  // if (yearsDifference > 0) {
  //   return yearsDifference === 1 ? '1 year ago' : `${yearsDifference} years ago`;
  // }

  // const monthsDifference = currentDate.diff(targetDate, 'month');
  // if (monthsDifference > 0) {
  //   return monthsDifference === 1 ? '1 month ago' : `${monthsDifference} months ago`;
  // }

  // const weeksDifference = currentDate.diff(targetDate, 'week');
  // if (weeksDifference > 0) {
  //   return weeksDifference === 1 ? '1 week ago' : `${weeksDifference} weeks ago`;
  // }

  // const daysDifference = currentDate.diff(targetDate, 'day');
  // if (daysDifference === 0) {
  //   return 'Today';
  // } else if (daysDifference === 1) {
  //   return 'Yesterday';
  // } else {
  //   return `${daysDifference} days ago`;
  // }
};
