import { Typography } from '@mui/material';
import React from 'react';

export const LeadColoredEvent = ({ color, title }) => {
  return (
    <Typography component="span" sx={{ fontWeight: '600', mx: 0.5, color: color }}>
      {title}
    </Typography>
  );
};
