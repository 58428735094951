const jobOffers = [
  {
    companyName: 'LIV Enterprises INC',
    companySlug: 'liv-enterprises',
    companyLogo: '/assets/images/LIV_LOGO.png',
    companyLocation: 'Illinois',
    companyInfo:
      'LIV (Logistics In Vision) is a privately owned company with locations in Chicago, IL and Miami, FL. Over the last decade, we have experienced significant growth in our Dry Van and Flatbed Divisions. With a fleet of 150 + experienced drivers and over 70 dedicated office staff, we are prepared to continue that success and provide top-notch opportunities to truck drivers across the country!',
    companiesJobPostsData: [
      {
        companyJobSlug: 'company-driver',
        companyJobRouteType: 'OTR',
        companyJobContract: '1099',
        companyJobTagline:
          'Flatbed & Dry Van CDL A Drivers Needed · $80,000 Average Annual Pay · Referral Program Available',
        companyRedLine: '<strong>Minimum</strong> 1 year OTR Experience - Clean Background',
        companyIsHiring: 'Hiring Company Drivers',
        hiringFrom: 'All 48',
        jobGeneralInfo: [
          'Terminal Location 1: 101 Royce Rd, Unit 16 Bolingbrook, Chicago, IL 60440',
          'Terminal Location 2: 940 Lincoln Rd Suite 314 Miami Beach, Miami, FL 33139',
          'Type of employment: 1099',
          'Position: Company Driver',
          'Trailer: Dry Van & Flatbed',
          'Routes: OTR',
        ],
        jobPayRate: [
          {
            truckType: 'Flatbed',
            payRate: {
              payRateInfo: [
                'Pay: 75 cpm',
                'Minimum Weekly: $2,000+',
                'Average Weekly Miles: 3,200',
                'Average Annual Pay: $80,000',
                'Tarp Pay: $35',
              ],
            },
          },
          {
            truckType: 'Dry Van',
            payRate: {
              payRateInfo: [
                'Pay: 65cpm',
                'Minimum Weekly: $1,800+',
                'Average Weekly Miles: 3,200',
                'Average Annual Pay: $80,000',
              ],
            },
          },
        ],
        moreJobInfo: [
          'No Touch Freight',
          'No Forced Dispatch',
          'Flexible Hometime',
          'Free of deductions during your home time',
          'Additional Stop: $25 each',
          'Weekly direct deposit',
          'Layover Pay',
          'Paid empty and loaded',
          'Pet friendly',
          'Referral Bonuses: 0.05 cpm raise for each referral + $500 cash bonus',
        ],
        requirements: ['Active CDL Class A', 'Minimum 1 year of experience', 'Clean criminal background'],
        equipment: ['Trucks: late models of Volvo, Freightliners, Peterbilt, Kenworth', 'Trucks are governed: No'],
        referralProgram:
          'We have built an innovative referral program to reward our drivers. Company drivers get a $.05 cpm raise for each referral, plus a $500 cash bonus. You can get as much as $1.49 per mile. The bonus applies as long as your referral is driving with us.',
      },
      {
        companyJobSlug: 'owner-operator',
        companyJobRouteType: 'OTR',
        companyJobContract: '1099',
        companyJobTagline: 'Signing-On Owner Operators · Flatbed & Reefer Trailers · Referral Bonuses Available',
        companyRedLine: '<strong>Minimum</strong> 1 year OTR Experience - Clean Background',
        companyIsHiring: 'Signing-On Owner-Operators (Lease Purchase Available)',
        hiringFrom: 'All 48',
        jobGeneralInfo: [
          'Terminal Location 1: 101 Royce Rd, Unit 16 Bolingbrook, Chicago, IL 60440',
          'Terminal Location 2: 940 Lincoln Rd Suite 314 Miami Beach, Miami, FL 33139',
          'Type of employment: 1099',
          'Position: Owner-Operator',
          'Trailer: Dry Van & Flatbed',
          'Routes: OTR',
        ],
        jobPayRate: [
          {
            payRate: {
              // insurance: [
              //   "Cargo & Liability - $200 Per Week",
              //   "Physical Damage - Depends on the Year and Value of the Truck",
              //   "Occupation/Accidental - $49.99 Per Week",
              // ],
              payRateInfo: ['Pay: 88% per load', 'Minimum Weekly: $8,000+'],
            },
          },
        ],
        moreJobInfo: [
          'No Touch Freight',
          'No Forced Dispatch',
          'Paid Orientation',
          'Flexible Hometime',
          'Additional Stop: $25 each',
          'Weekly direct deposit',
          'ELD: SAMSARA & Keep Trucking',
          'Plates: Provided',
          '2290: Provided',
          'Fuel Card: EFS fuel cards are provided and you can fuel on every Truck Stop in the US',
          'Original Rate Confirmation',
          'Referral Bonuses: 1% to your gross for each referral + $1,000 cash bonus',
        ],
        deductions: [
          'Truck & Physical Damage Insurance: depends on the year and value of the truck',
          'IFTA Deductions: Every quarter which is 4 times a year you will be charged accordingly depending on the miles traveled per state and the fuel used',
          'Cargo & Liability Insurance: $200 / weekly',
          'Occupational Insurance: $49.99 / weekly',
          'Trailer Rent: $400 Dry Van / $250 Flatbed',
        ],
        leasePurchaseArr: ['No down checks', 'No balloon payment'],
        // leasePurchaseOffer:
        //   "Best Fleet Equipment for anyone looking at Lease Purchase Trucking Opportunities. Great equipment with affordable payments to help you achieve the best profit in the trucking industry! You pay NO interest over time and NO balloon fees!",
        requirements: ['Active CDL Class A', 'Minimum 1 year of experience', 'Clean criminal background'],

        referralProgram:
          'We have built an innovative referral program to reward our drivers. Independent contractors get a 1% raise to your gross for each referral, plus a $1,000 cash bonus. You can get 100% of gross revenue with the referral bonuses. The bonus applies as long as your referral is driving with us.',
      },
    ],
  },
];
export default jobOffers;
