import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Button, OutlinedInput, InputAdornment, Box } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Iconify from '../../../components/iconify';
import { useEffect, useState } from 'react';
import useGetLeadsBySearch from 'src/hooks/useGetLeadsBySearch';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 72,
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '6px !important',
  paddingRight: '6px !important',
  flexShrink: 1,
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  maxWidth: 200,
  transition: theme.transitions.create(['box-shadow'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
  '&:hover': {
    boxShadow: `0 4px 15px ${alpha(theme.palette.common.black, 0.15)}`,
  },
  '& input': {
    padding: '10px 0',
  },
}));

const SearchButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.success.main,
  padding: '6px 16px',
  fontSize: '0.875rem',
  minWidth: '64px',
  boxShadow: `0 2px 4px ${theme.palette.grey[500]}`,
  transition: theme.transitions.create(['background-color', 'box-shadow', 'transform'], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }),
  '&:hover': {
    backgroundColor: theme.palette.success.dark,
    boxShadow: `0 4px 8px ${theme.palette.grey[700]}`,
    transform: 'translateY(-2px)',
  },
  '&:active': {
    boxShadow: `0 1px 2px ${theme.palette.grey[700]}`,
    transform: 'translateY(1px)',
  },
  borderRadius: '8px',
  textTransform: 'none',
  letterSpacing: 'normal',
  fontWeight: 500,
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({
  user,
  filterName,
  onFilterName,
  currentSearchedStatus,
  currentSourceId,
  setFetchingDataLoader,
  setAllDrivers,
  rowsPerPage,
  setTotalLeads,
  filterByRecruiter,
  filterByPosition,
}) {
  const [searchValue, setSearchValue] = useState(filterName || '');

  useEffect(() => {
    setSearchValue('');
    onFilterName('');
  }, [currentSearchedStatus, currentSourceId]);

  const handleSearch = (searchValueInput) => {
    onFilterName(searchValueInput);
    setFetchingDataLoader(false);
    setAllDrivers([]);
    const searchedStatus = currentSearchedStatus.toLowerCase();
    useGetLeadsBySearch({
      companyId: user.company_id,
      leadSource: currentSourceId,
      currentStatus: searchedStatus,
      searchTerm: searchValueInput,
      page: 0,
      recruiterId: user.recruiter_id ?? filterByRecruiter,
      rowsPerPage,
      filteredPosition: filterByPosition,
    })
      .then((data) => {
        const finalLeads = Array.isArray(data.lead_list)
          ? data.lead_list.map((lead) => {
              return {
                ...lead,
                lead_status: data.status_list[lead.lead_id].status || 'not contacted',
                driverNotes: data.notes_list[lead.lead_id] || [],
                lead_status_modified: data.status_list[lead.lead_id].status_modified,
              };
            })
          : [];

        setTotalLeads(data.total_leads);
        setAllDrivers(finalLeads);
      })
      .catch((error) => {
        console.error('Something went wrong', error);
      })
      .finally(() => {
        setFetchingDataLoader(true);
      });
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value === '') {
      handleSearch('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch(searchValue);
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
    handleSearch('');
  };

  return (
    <StyledRoot>
      <StyledSearch
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        placeholder="Search lead..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
        endAdornment={
          searchValue && (
            <InputAdornment position="end">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={handleClearSearch}
              >
                <ClearOutlinedIcon color="error" fontSize="small" sx={{ opacity: 0.8, cursor: 'pointer' }} />
              </Box>
            </InputAdornment>
          )
        }
      />
      <SearchButton
        color="success"
        sx={{
          marginLeft: '10px',
        }}
        onClick={() => {
          handleSearch(searchValue);
        }}
      >
        Search
      </SearchButton>
    </StyledRoot>
  );
}
