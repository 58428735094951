import { Box, Typography } from '@mui/material';
import React from 'react';

const SoonButton = () => {
  return (
    <Box
      sx={{
        marginLeft: '18px',
        backgroundColor: 'lightblue',
        borderRadius: '10px',
        padding: '2px 8px',
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
        Soon
      </Typography>
    </Box>
  );
};

export default SoonButton;
